import { useRef } from "react";
import {
  Create,
  Link,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useTranslate,
} from "react-admin"; // eslint-disable-line import/no-unresolved
import Button from "@material-ui/core/Button";
import useAuth from "../hook/useAuth";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import useMutation from "../hook/useMutation";

const ProjectCreate = ({ ...props }) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "40%",
    },
  }));

  const projectname = useRef<HTMLInputElement>(null!);
  const description = useRef<HTMLInputElement>(null!);
  const classes = useStyles();
  const user = useAuth();
  const history = useHistory();
  const [createProjectMutation] = useMutation(
    user.cognitoUser,
    "createProject"
  );

  let errors = {};
  const validateProjectCreation = (values) => {
    errors = {};
    if (!values.projectname) {
      errors["projectname"] = "プロジェクト名は必須です";
    }
    if (!values.description) {
      errors["description"] = "プロジェクト概要は必須です";
    }

    return errors;
  };

  const handleProjectCreate = (): void => {
    if (Object.keys(errors).length === 0) {
      const newProjectId = user.cognitoUser?.username + "-" + Date.now();
      createProjectMutation({
        projectId: newProjectId,
        projectName: projectname.current.value,
        description: description.current.value,
      })
        .then(() => {
          dataProvider.create("projects", {
            data: {
              description: description.current.value,
              project_id: newProjectId,
              project_name: projectname.current.value,
              regulations: [],
            },
          });
          history.push("/projects");
        })
        .catch((e) => console.error(e));
    }
  };

  const ProjectToolbar = (props) => (
    <Toolbar {...props} style={{ paddingLeft: 16, paddingRight: 16 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleProjectCreate}
        style={{ marginTop: 10, marginRight: 10, marginBottom: 10 }}
      >
        {translate("resources.projects.create.create")}
      </Button>
      <Link to="/projects">
        <Button variant="outlined" color="primary" style={{ margin: 10 }}>
          {translate("resources.projects.create.cansel")}
        </Button>
      </Link>
    </Toolbar>
  );

  return (
    <Create {...props}>
      <SimpleForm
        validate={validateProjectCreation}
        toolbar={<ProjectToolbar />}
      >
        <TextInput
          source="projectname"
          label="resources.projects.create.name"
          fullWidth
          inputRef={projectname}
          className={classes.root}
        />
        <TextInput
          source="description"
          label="resources.projects.create.description"
          fullWidth
          inputRef={description}
          className={classes.root}
        />
      </SimpleForm>
    </Create>
  );
};

export default ProjectCreate;
