import * as React from "react";
import { useSelector } from "react-redux";
import { Layout, LayoutProps, useSetLocale } from "react-admin";
import AppBar from "./AppBar";
import Menu from "./Menu";
import { darkTheme, lightTheme } from "./themes";
import { AppState } from "../types";

const ExportJSX = (props: LayoutProps) => {
  const theme = useSelector((state: AppState) =>
    state.theme === "dark" ? darkTheme : lightTheme
  );
  const setLocale = useSetLocale();

  const mylocale = localStorage.getItem("mylocale");
  React.useEffect(() => {
    if (mylocale) {
      setLocale(mylocale);
    }
  }, [mylocale, setLocale]);

  return <Layout {...props} appBar={AppBar} menu={Menu} theme={theme} />;
};

export default ExportJSX;
