import FlagIcon from '@material-ui/icons/Flag';
import ProjectCreate from './ProjectCreate';
import ProjectList from "./ProjectList";

const exportedObject = {
  icon: FlagIcon,
  list: ProjectList,
  create: ProjectCreate,
};

export default exportedObject;
