import { ReactElement, useEffect, useMemo, useState } from "react";
import {
  CreateButton,
  Datagrid,
  DateField,
  List,
  ListProps,
  TextField,
  TopToolbar,
  useTranslate,
} from "react-admin";
import { Drawer, Snackbar } from "@material-ui/core";
import { Route, RouteChildrenProps, useLocation } from "react-router-dom";
import useAuth from "../hook/useAuth";
import { makeStyles } from "@material-ui/core/styles";
import UserEdit from "./UserEdit";

const rowClick = (id, basePath, record) => {
  return "edit";
};

const UserActions = (props) => (
  <TopToolbar>
    <CreateButton variant="contained" label="resources.users.create.create" />
  </TopToolbar>
);

const UserList = (props: ListProps): ReactElement => {
  const useStyles = makeStyles((theme) => ({
    list_root: {
      width: "60%",
    },
    drawerPaper: {
      paddingTop: "50px",
      zIndex: 100,
    },
    data_grid: {
      backgroundColor: "#212121",
    },
  }));

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setErrorSnackbar(false);
  };

  const classes = useStyles();
  const user = useAuth();
  const translate = useTranslate();
  const localtion = useLocation();
  const query = useMemo(() => new URLSearchParams(localtion.search), [
    localtion.search,
  ]);

  useEffect(() => {
    if (query?.get("openSnackbar") === "1") {
      setOpenSnackbar(true);
    } else if (query?.get("openSnackbar") === "2") {
      setOpenSnackbar(true);
      setErrorSnackbar(true);
    }
  }, [query]);

  if (!user) {
    return <></>;
  }

  return (
    <div>
      <Route path="/users/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );
          return (
            <div>
              <List
                {...props}
                exporter={false}
                bulkActionButtons={false}
                actions={<UserActions />}
                classes={{ root: classes.list_root }}
              >
                <Datagrid
                  rowClick={rowClick}
                  classes={{ headerCell: classes.data_grid }}
                >
                  <TextField
                    source="name"
                    label="resources.users.fields.name"
                  />
                  <DateField
                    source="create"
                    sortByOrder="DESC"
                    label="resources.users.fields.create"
                  />
                  <DateField
                    source="lastupdate"
                    label="resources.users.fields.lastupdate"
                  />
                </Datagrid>
              </List>
              <Drawer
                variant="persistent"
                open={true}
                anchor="right"
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {isMatch && (
                  <UserEdit
                    id={(match as any).params.id}
                    resource={props.resource}
                    basePath={props.basePath}
                    user={user}
                  />
                )}
              </Drawer>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                autoHideDuration={6000}
                open={openSnackbar}
                onClose={handleSnackbarClose}
                message={
                  !errorSnackbar
                    ? translate("resources.users.snackbar.success")
                    : translate("resources.users.snackbar.error")
                }
              />
            </div>
          );
        }}
      </Route>
    </div>
  );
};

export default UserList;
