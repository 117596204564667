import CategoryIcon from "@material-ui/icons/Bookmark";

import CategoryList from "./CategoryList";

const exportedObject = {
  list: CategoryList,
  icon: CategoryIcon,
};

export default exportedObject;
