import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FieldProps } from "react-admin";
import { Link } from "react-router-dom";
import { Project } from "../types";

const useStyles = makeStyles({
  link: {
    color: "#007cb0",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const ProjectList = ({ record }: FieldProps<Project>) => {
  const classes = useStyles();

  return record ? (
    <Box>
      {record.regulations &&
        record.regulations.map((reg, i) => {
          return reg ? (
            <Box key={i}>
              <Link to={"/legals/" + reg.regulation_id} class="router-link">
                <Typography
                  component="span"
                  variant="body1"
                  className={classes.link}
                >
                  {reg.regulation_name}
                </Typography>
              </Link>
            </Box>
          ) : null;
        })}
    </Box>
  ) : null;
};

ProjectList.defaultProps = {
  addLabel: true,
  label: "resources.projects.fields.regal",
};

export default ProjectList;
