import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { useTranslate, useQueryWithStore } from "react-admin";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.common.white,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
)(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  link: {
    borderRadius: 0,
  },
  linkContent: {
    color: theme.palette.primary.main,
  },
  head: {
    color: theme.palette.common.white,
  },
  emptyCard: {
    background: "#303030",
    textAlign: "center",
    height: "257px",
    padding: "24px",
  },
  card: {
    background: "#303030",
  },
  container: {
    background: "#303030",
  },
  button: {
    justifyContent: "center",
    alignItems: "end",
    width: "100%",
    padding: 0,
  },
  tableRow: {
    cursor: "pointer",
  },
  tableBody: {
    height: "215px",
  },
}));

const getOneWeekBeforeDate = () => {
  let dt = new Date();
  dt.setDate(dt.getDate() - 7);
  var yyyy = dt.getFullYear();
  var mm = ("0" + (dt.getMonth() + 1)).slice(-2);
  var dd = ("0" + dt.getDate()).slice(-2);

  return yyyy + "-" + mm + "-" + dd;
};


const WeeklyUpdateLegalList = () => {
  const translate = useTranslate();
  const history = useHistory();
  const classes = useStyles();

  const result = useQueryWithStore({
    type: "getList",
    resource: "legals",
    payload: {
      sort: { field: "publishCriteriaDate", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
      filter: {
        publishCriteriaDate_gte: getOneWeekBeforeDate(),
      },
    },
  });

  if (!result.loaded) return null;

  return result.data.length !== 0 ? (
    <Card className={classes.card}>
      <CardContent className={classes.card}>
        <TableContainer component={Paper} className={classes.container}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">
                  {translate("pos.dashboard.legals.name")}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {translate("pos.dashboard.legals.announceDate")}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {translate("pos.dashboard.legals.entryDate")}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {translate("pos.dashboard.legals.status")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {result.data
                ? result.data.map((row, index) =>
                    index < 3 ? (
                      <StyledTableRow
                        hover
                        key={row.id}
                        onClick={() => history.push(`/legals/${row.id}`)}
                        className={classes.tableRow}
                      >
                        <StyledTableCell component="th" scope="row">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.announceDate
                            ? row.announceDate.substring(0, 10)
                            : ""}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.entryDate ? row.entryDate.substring(0, 10) : ""}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {translate("resources.status.data." + row.status)}
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : null
                  )
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Toolbar className={classes.button}>
        <Button
          className={classes.link}
          component={Link}
          to="/legals"
          size="small"
          color="primary"
          fullWidth
        >
          <Box p={1} className={classes.linkContent}>
            {translate("pos.dashboard.all_news")}
          </Box>
        </Button>
      </Toolbar>
    </Card>
  ) : (
    <Card className={classes.card}>
      <CardContent className={classes.emptyCard}>
        <Typography variant="body2">
          {translate("pos.dashboard.weekly_update_legal_empty")}
        </Typography>
      </CardContent>
      <Toolbar className={classes.button}>
        <Button
          className={classes.link}
          component={Link}
          to="/legals"
          size="small"
          color="primary"
          fullWidth
        >
          <Box p={1} className={classes.linkContent}>
            {translate("pos.dashboard.all_news")}
          </Box>
        </Button>
      </Toolbar>
    </Card>
  );
};

export default WeeklyUpdateLegalList;
