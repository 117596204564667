import { useCallback, useMemo, useState } from "react";
import axios from "axios";

export const useMutation = function (user: any, sqlid: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<any>();
  const [error, setError] = useState<Error>();

  const idToken = useMemo(
    () => user?.signInUserSession?.idToken.jwtToken,
    [user]
  );
  const accessToken = useMemo(
    () => user?.signInUserSession?.accessToken.jwtToken,
    [user]
  );

  const sqlidMome = useMemo(() => sqlid, [sqlid]);

  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      authorization: idToken,
      "x-access-token": accessToken,
    }),
    [idToken, accessToken]
  );

  const API_URL = process.env.REACT_APP_APIGATEWAY_ENDPOINT + "/prod/data";

  const mutation = useCallback(
    async (pramSqlVariable: object): Promise<void> => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          API_URL,
          { sqlId: sqlidMome, sqlVariable: pramSqlVariable },
          { headers }
        );
        if (response) {
          setResult(response);
        } else {
          throw Error(response);
        }
      } catch (e) {
        console.error(e);
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [API_URL, headers, sqlidMome]
  );

  return [mutation, { isLoading, error, result }] as const;
};

export default useMutation;
