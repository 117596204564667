import {
  Box,
  Button,
  Paper,
  Checkbox,
  IconButton,
  Typography,
} from "@material-ui/core";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import {
  FieldProps,
  SimpleForm,
  TextInput,
  useDataProvider,
  useGetList,
  useRefresh,
  useTranslate,
} from "react-admin";
import { Legal } from "../types";
import { makeStyles } from "@material-ui/core/styles";
import useAuth from "../hook/useAuth";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React, { useRef, useMemo } from "react";
import useMutation from "../hook/useMutation";
import { useHistory, useLocation } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles({
  link: {
    cursor: "pointer",
  },
  title: {
    position: "absolute",
    top: "8px",
    right: "20px",
  },
});

const ProjectList = ({ record }: FieldProps<Legal>) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const projectsData = useGetList(
    "projects",
    { page: 1, perPage: 99999 },
    { field: "id", order: "ASC" }
  );
  const user = useAuth();
  const history = useHistory();
  const localtion = useLocation();
  const query = useMemo(() => new URLSearchParams(localtion.search), [
    localtion.search,
  ]);
  const refresh = useRefresh();
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const projectname = useRef<HTMLInputElement>(null!);
  const description = useRef<HTMLInputElement>(null!);

  const [createProjectMutation] = useMutation(
    user.cognitoUser,
    "createProject"
  );
  const [addProjectMutation, addProjectMutationResult] = useMutation(
    user.cognitoUser,
    "createProjectRegulation"
  );
  const [deleteProjectMutation, deleteProjectMutationResult] = useMutation(
    user.cognitoUser,
    "deleteProjectRegulation"
  );

  React.useEffect(() => {
    setErrorSnackbar(
      !addProjectMutationResult.error || !deleteProjectMutationResult.error
    );
    if (query?.get("openSnackbar")) {
      setOpen(true);
      setOpenSnackbar(true);
    }
  }, [
    addProjectMutationResult.error,
    deleteProjectMutationResult.error,
    localtion.pathname,
    query,
  ]);

  let errors = {};
  const validateProjectCreation = (values) => {
    errors = {};
    if (!values.projectname) {
      errors["projectname"] = "お気に入り名称は必須です";
    }
    if (!values.description) {
      errors["description"] = "お気に入り説明は必須です";
    }

    return errors;
  };

  const handleProjectCreate = (): void => {
    if (Object.keys(errors).length === 0) {
      const newProjectId = user.cognitoUser?.username + "-" + Date.now();

      createProjectMutation({
        projectId: newProjectId,
        projectName: projectname.current.value,
        description: description.current.value,
      })
        .then(() => {
          dataProvider.create("projects", {
            data: {
              description: description.current.value,
              project_id: newProjectId,
              project_name: projectname.current.value,
              regulations: [],
            },
          });
          refresh();
          history.push(localtion.pathname + "?openSnackbar=1");
        })
        .catch((e) => console.error(e));
    }
  };

  const handleSave = (isAdd, projectId, id, prevRegs, projectName) => {
    try {
      if (isAdd) {
        addProjectMutation({
          projectId: projectId,
          regulationId: record!.id,
        }).then(() => {
          prevRegs.push({
            regulation_id: record?.id,
            regulation_name: record?.name,
          });
          dataProvider
            .update("projects", {
              id: id,
              data: {
                regulations: prevRegs,
              },
              previousData: { id: id },
            })
            .then(() => {
              dataProvider
                .update("legals", {
                  id: record!.id,
                  data: {
                    projectName: record?.projectName
                      ? [...record?.projectName, projectName]
                      : [projectName],
                  },
                  previousData: { id: record!.id },
                })
                .then(() => {
                  refresh();
                  history.push(localtion.pathname + "?openSnackbar=1");
                });
            });
        });
      } else {
        deleteProjectMutation({
          projectId: projectId,
          regulationId: record!.id,
        }).then(() => {
          dataProvider
            .update("projects", {
              id: id,
              data: {
                regulations: prevRegs.filter(
                  (reg) => reg.regulation_id !== record?.id
                ),
              },
              previousData: { id: id },
            })
            .then(() => {
              dataProvider
                .update("legals", {
                  id: record!.id,
                  data: {
                    projectName: record?.projectName.filter(
                      (prj) => prj !== projectName
                    ),
                  },
                  previousData: { id: record!.id },
                })
                .then(() => {
                  refresh();
                  history.push(localtion.pathname + "?openSnackbar=1");
                });
            });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    refresh();
    history.push(localtion.pathname);
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  // console.log(projectsData);
  // console.log(record);
  return (
    <>
      <Box display="flex" alignItems="center">
        <IconButton onClick={() => setOpen(true)}>
          <PlaylistAddIcon />
        </IconButton>
        <Typography
          component="span"
          variant="body1"
          onClick={() => setOpen(true)}
          className={classes.link}
        >
          {translate("resources.legals.fields.project")}
        </Typography>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {translate("resources.legals.fields.project")}
          <IconButton onClick={handleClose} className={classes.title}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper>
            <Typography variant="body2">
              {translate("resources.projects.createRelation.createAreaName")}
            </Typography>
            <SimpleForm
              basepath={"/legals"}
              validate={validateProjectCreation}
              toolbar={<></>}
            >
              <TextInput
                source="projectname"
                label="resources.projects.create.name"
                fullWidth
                inputRef={projectname}
                style={{ marginBottom: "0px" }}
              />
              <TextInput
                source="description"
                label="resources.projects.create.description"
                fullWidth
                inputRef={description}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleProjectCreate}
                fullWidth
              >
                {translate("resources.projects.save.label")}
              </Button>
            </SimpleForm>
          </Paper>
          <Paper style={{ marginTop: 20 }}>
            <Typography variant="body2">
              {translate(
                "resources.projects.createRelation.editRelationAreaName"
              )}
            </Typography>
            <SimpleForm basepath={"/legals"} toolbar={<></>}>
              <List>
                {!projectsData.loading &&
                  Object.keys(projectsData.data).map((key, index) => {
                    return (
                      <ListItem
                        key={index}
                        button
                        style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        onClick={() =>
                          handleSave(
                            !projectsData.data[key]?.regulations.find(
                              (reg) => reg.regulation_id === record?.id
                            ),
                            projectsData.data[key]?.project_id,
                            projectsData.data[key]?.id,
                            projectsData.data[key]?.regulations,
                            projectsData.data[key]?.project_name
                          )
                        }
                      >
                        <ListItemIcon>
                          <Checkbox
                            checked={projectsData.data[key]?.regulations.some(
                              (reg) => reg.regulation_id === record?.id
                            )}
                            color="primary"
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography component="span" variant="body1">
                            {projectsData.data[key]?.project_name}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    );
                  })}
              </List>
            </SimpleForm>
          </Paper>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={6000}
        open={openSnackbar}
        onClose={handleSnackbarClose}
        message={
          errorSnackbar
            ? translate("resources.projects.snackbar.success")
            : translate("resources.projects.snackbar.error")
        }
      />
    </>
  );
};

ProjectList.defaultProps = {};

export default ProjectList;
