import { TranslationMessages } from "react-admin";
import englishMessages from "ra-language-english";
// import japaneseMessages from "@bicstone/ra-language-japanese";

const customJapaneseMessages: TranslationMessages = {
  ...englishMessages,
  appbar: {
    title: "Global Technology Regulation",
    term_name: "利用規約",
    terms: {
      jp: "日本語",
      cn: "中文",
    },
    privacy_policy: "プライバシーポリシー",
  },
  pos: {
    search: "Search",
    configuration: "Configuration",
    language: "Language",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    dashboard: {
      welcome: {
        title: "Global Technology Regulation",
        subtitle1:
          "Global Technology Regulationは法規制対応のファーストステップである「規制情報の収集と分析」を効率的に行うことが出来るサービスです。",
        subtitle2:
          "デロイトが蓄積した法令原文と翻訳文書、重要法令のサマリ、要件の解説に加え、定期的な法令動向情報を提供します。",
      },
      weekly_update_legal: "週間更新法令",
      weekly_update_legal_empty: "1週間以内の更新はありません。",
      month_history: "30 Day Revenue History",
      new_orders: "New Orders",
      pending_reviews: "Pending Reviews",
      all_reviews: "See all reviews",
      news: "お知らせ",
      all_news: "すべて表示",
      follows: "フォロー",
      all_follows: "すべて表示",
      favorites: "お気に入り",
      all_favorites: "すべて表示",
      categorys: "カテゴリ",
      all_categorys: "すべて表示",
      keywords: "キーワード",
      all_keywords: "すべて表示",
      topics: "トピック",
      all_topics: "すべて表示",
      statuses: "法令ステータス",
      all_statuses: "すべて表示",
      pending_orders: "Pending Orders",
      order: {
        items:
          "by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items",
      },
      legals: {
        name: "法令名",
        announceDate: "公布日",
        entryDate: "施行日",
        status: "法令ステータス",
      },
    },
    menu: {
      legals: "レギュレーション",
      settings: "設定",
    },
  },
  resources: {
    common: {
      yes: "はい",
      no: "いいえ",
    },
    news: {
      name: "お知らせ",
      fields: {
        id: "ID",
        content: "内容",
        create: "発行日",
        lastupdate: "更新日",
      },
      empty: "まだお知らせがありません。",
    },
    projects: {
      name: "お気に入り",
      detail: "お気に入り詳細",
      data: {
        1: "製品Ａの中国展開プロジェクト",
        2: "製品Ｂの中国展開プロジェクト",
      },
      fields: {
        id: "ID",
        name: "名称",
        description: "説明",
        regal: "関連法令",
      },
      save: {
        label: "保存",
      },
      delete: {
        label: "削除",
        title: "削除確認",
        message: "お気に入りを削除しますか？",
      },
      dialog: {
        title: "保存確認",
        message: "お気に入りを保存しますか？",
      },
      snackbar: {
        success: "更新が成功しました。",
        error: "更新に失敗しました。",
      },
      create: {
        create: "お気に入り作成",
        cansel: "キャンセル",
        name: "お気に入り名称",
        description: "お気に入り説明",
      },
      createRelation: {
        createAreaName: "お気に入り作成",
        editRelationAreaName: "お気に入り編集",
      },
      empty: {
        empty: "まだお気に入りが作成されていません。",
        invite: "お気に入りを作成しますか？",
      },
    },
    follows: {
      name: "フォロー",
      data: {
        1: "調査中のレギュレーション",
        2: "監視が必要なレギュレーション",
      },
    },
    categorys: {
      name: "カテゴリー",
    },
    users: {
      name: "ユーザ",
      detail: "ユーザ詳細",
      fields: {
        id: "ID",
        name: "メールアドレス",
        create: "作成日",
        lastupdate: "更新日",
      },
      delete: {
        label: "ユーザ削除",
        title: "削除確認",
        message: "ユーザを削除しますか？",
      },
      create: {
        create: "ユーザ作成",
      },
      snackbar: {
        success: "更新が成功しました。",
        error: "更新に失敗しました。",
      },
    },
    legals: {
      name: "法令検索",
      detail: "法令詳細",
      fields: {
        id: "ID",
        name: "名称(日本語)",
        cnname: "名称(中国語)",
        category: "カテゴリー",
        requirement: "主要要件",
        topic: "トピック",
        status: "法令ステータス",
        entrydate: "施行日",
        create: "登録日",
        lastupdate: "最終更新日",
        rate: "重要度",
        description: "説明",
        division: "規約区分",
        originalContent: "原文",
        japaneseContent: "翻訳",
        interpretationContent: "要約",
        announceDate: "公布日",
        project: "お気に入り",
      },
      filters: {
        last_visited: "最終更新日",
        today: "当日",
        this_week: "当週",
        last_week: "先週",
        this_month: "当月",
        last_month: "先月",
        earlier: "先月～当日",
        project: "お気に入り",
        follow: "フォロー",
        category: "カテゴリ",
        keyword: "キーワード",
        topic: "トピック",
        status: "法令ステータス",
        group: "Segment",
        req: "主要要件",
        announceDate: "公布日",
      },
    },
    customers: {
      name: "Customer |||| Customers",
      fields: {
        commands: "Orders",
        first_seen: "First seen",
        groups: "Segments",
        last_seen: "Last seen",
        last_seen_gte: "Visited Since",
        name: "Name",
        total_spent: "Total spent",
        password: "Password",
        confirm_password: "Confirm password",
        stateAbbr: "State",
      },
      filters: {
        last_visited: "Last visited",
        today: "Today",
        this_week: "This week",
        last_week: "Last week",
        this_month: "This month",
        last_month: "Last month",
        earlier: "Earlier",
        has_ordered: "Has",
        has_newsletter: "Has newsletter",
        group: "Segment",
      },
      fieldGroups: {
        identity: "Identity",
        address: "Address",
        stats: "Stats",
        history: "History",
        password: "Password",
        change_password: "Change Password",
      },
      page: {
        delete: "Delete Customer",
      },
      errors: {
        password_mismatch:
          "The password confirmation is not the same as the password.",
      },
    },
    commands: {
      name: "Order |||| Orders",
      amount: "1 order |||| %{smart_count} orders",
      title: "Order %{reference}",
      fields: {
        basket: {
          delivery: "Delivery",
          reference: "Reference",
          quantity: "Quantity",
          sum: "Sum",
          tax_rate: "Tax Rate",
          taxes: "Tax",
          total: "Total",
          unit_price: "Unit Price",
        },
        address: "Address",
        customer_id: "Customer",
        date_gte: "Passed Since",
        date_lte: "Passed Before",
        nb_items: "Nb Items",
        total_gte: "Min amount",
        status: "Status",
        returned: "Returned",
      },
      section: {
        order: "Order",
        customer: "Customer",
        shipping_address: "Shipping Address",
        items: "Items",
        total: "Totals",
      },
    },
    categories: {
      name: "Category |||| Categories",
      fields: {
        products: "Products",
      },
      data: {
        1: "CS関連法令・弁法",
        2: "プライバシー関連法令・弁法",
        3: "CS関連標準・ガイドライン",
        4: "CS・プライバシー関連以外",
        5: "プライバシー関連標準・ガイドライン",
      },
    },
    requirement: {
      name: "主要要件",
      data: {
        1: "等級保護",
        2: "ネットワーク製品・安全認証",
        3: "緊急対応",
        4: "重要インフラ事業者",
        5: "データ越境制限",
        6: "個人情報保護",
        7: "その他_全般",
      },
    },
    topics: {
      name: "トピック",
      data: {
        1: "金融",
        2: "インターネット",
      },
    },
    status: {
      name: "法令ステータス",
      data: {
        1: "意見募集",
        2: "施行予定",
        3: "施行済",
        4: "失効",
      },
    },
    division: {
      name: "規約区分",
    },
    legalsmap: {
      name: "俯瞰図",
      legend: "凡例",
    },
    terms: {
      title: "利用規約",
      link: "利用規約を表示する",
      subtitle:
        "利用規約をご確認の上、同意いただける場合は下記のチェックボックスにチェックを付けて続行してください。",
      checkbox: "利用規約に同意する",
      nextbutton: "続行する",
      snackbar: "更新に失敗しました。管理者にお問合せください。",
    },
    filenotfound: {
      title: "ファイルが見つかりませんでした",
      subtitle:
        "ファイルのダウンロードに失敗しました。何度もこの画面が表示される場合は、お手数ですが管理者にお問い合わせください。",
    },
  },
};

export default customJapaneseMessages;
