import { Card, CardContent, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ListProps, useTranslate } from "react-admin";

const useStyles = makeStyles({
  title: {
    marginBottom: 20,
    textAlign: "center",
  },
  subtitle: {
    marginBottom: 20,
  },
  link: {
    marginBottom: 10,
    cursor: "pointer",
    fontWeight: "bold",
  },
});

const FileNotFound = (props: ListProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Container maxWidth="sm">
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2" className={classes.title}>
            {translate("resources.filenotfound.title")}
          </Typography>
          <Typography
            variant="body1"
            component="p"
            className={classes.subtitle}
          >
            {translate("resources.filenotfound.subtitle")}
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default FileNotFound;
