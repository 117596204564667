import React from "react";
import { Auth } from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import {
  AuthState,
  onAuthUIStateChange,
  Translations,
} from "@aws-amplify/ui-components";
import { Admin, Resource } from "react-admin";
import { Layout } from "./layout";
import { Dashboard } from "./dashboard";
import categorys from "./categorys";
import legals from "./legals";
import "./App.css";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "./i18n/en";
import customRoutes from "./routes";
import themeReducer from "./themeReducer";
import fakeDataProvider from "ra-data-fakerest";
import { I18n } from "aws-amplify";
import useQuery from "./hook/useQuery";
import { mergeQueryResultJson } from "./util/JsonFormat";
import legalsmap from "./legalsmap";
import { UserContextProvider } from "./contexts/UserContext";
import useUserQuery from "./hook/useUserQuery";
import users from "./users";
import customJapaneseMessages from "./i18n/ja";
import projects from "./projects";
import useFileData from "./hook/useFileData";
import filenotfound from "./filenotfound";
import news from "./news";
// import terms from "./terms";
// import TermsLayout from "./terms/TermsLayout";

const messages = {
  ja: customJapaneseMessages,
  en: englishMessages,
};

messages.ja.ra.navigation.no_results = "該当データはありません";

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "ja");

I18n.putVocabularies(Translations);
I18n.setLanguage("ja");

I18n.putVocabularies({
  ja: {
    "Sign In": "サインイン",
    "Sign Up": "サインアップ",
    "Sign in to your account": "ログイン情報を入力してください",
    "Forgot your password?": "パスワードをお忘れですか？",
    "Reset password": "パスワードをリセット",
    "Reset your password": "パスワードリセット",
    "Verification code": "認証コード",
    "New password": "新しいパスワード",
    "Back to Sign In": "ログインに戻る",
    Submit: "送信",
    "Username *": "メールアドレス *",
    "Send Code": "リセットメール送信",
    "Enter your username": "メールアドレスを入力してください",
    "Enter your new password": "新しいパスワードを入力してください",
    "Confirm TOTP Code": "MFA認証コード確認",
    "Enter code": "認証コードを入力してください",
    Confirm: "送信",
  },
});

const isGetUesrListEnd = (user, getUesrListResult) => {
  return !user?.attributes
    ? false
    : user?.attributes["custom:admin"] === "1"
    ? getUesrListResult.result && !getUesrListResult.isLoading
    : true;
};

const App: React.FC = () => {
  const [authState, setAuthState] = React.useState<any>();
  const [user, setUser] = React.useState<any>();
  const [agreedVersions, setAgreedVersions] = React.useState<string>("");
  const fileData = useFileData(user, "terms/terms-of-service.txt");

  const userId = React.useMemo(() => user?.username, [user]);

  const getRegulationSearchBaseListResult = useQuery(
    user,
    "getRegulationSearchBaseList"
  );

  const getProjectResult = useQuery(user, "getProject", {
    delFlg: "0",
    userId: userId,
  });
  const getDivisionResult = useQuery(user, "getDivision");
  const getCategoryResult = useQuery(user, "getCategory");

  const getRequirementListResult = useQuery(user, "getRequirementList");
  const getRegulationContentListResult = useQuery(
    user,
    "getRegulationContentList"
  );

  const getUesrListResult = useUserQuery(user, "list");

  const getRegulationHistoryListResult = useQuery(
    user,
    "getRegulationHistoryList"
  );
  const getProjectRegulationResult = useQuery(user, "getProjectRegulation", {
    delFlg: "0",
    userId: userId,
  });

  const getNewsList = useQuery(user, "getNewsList");

  let dataProvider;
  let resultJson;

  if (
    getRegulationSearchBaseListResult.result &&
    !getRegulationSearchBaseListResult.isLoading &&
    getProjectResult.result &&
    !getProjectResult.isLoading &&
    getDivisionResult.result &&
    !getDivisionResult.isLoading &&
    getCategoryResult.result &&
    !getCategoryResult.isLoading &&
    getRequirementListResult.result &&
    !getRequirementListResult.isLoading &&
    getRegulationContentListResult.result &&
    !getRegulationContentListResult.isLoading &&
    getRegulationHistoryListResult.result &&
    !getRegulationHistoryListResult.isLoading &&
    getProjectRegulationResult.result &&
    !getProjectRegulationResult.isLoading &&
    isGetUesrListEnd(user, getUesrListResult) &&
    fileData.result &&
    !fileData.isLoading &&
    getNewsList.result &&
    !getNewsList.isLoading
  ) {
    resultJson = mergeQueryResultJson(
      getRegulationSearchBaseListResult.result,
      getProjectResult.result,
      getDivisionResult.result,
      getCategoryResult.result,
      getRequirementListResult.result,
      getRegulationContentListResult.result,
      getUesrListResult.result === undefined ? [] : getUesrListResult.result,
      getRegulationHistoryListResult.result,
      getProjectRegulationResult.result,
      fileData.result,
      getNewsList.result
    );
    dataProvider = fakeDataProvider(resultJson);
  }

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
      authData &&
        nextAuthState === AuthState.SignedIn &&
        Auth.userAttributes(authData).then((value) => {
          value.forEach(
            (v) =>
              v.Name === "custom:agreedVersions" && setAgreedVersions(v.Value)
          );
        });
    });
  }, []);

  let latestAgreedVersion = "";
  if (agreedVersions) {
    // ToDo 利用規約の同意画面を利用する場合eslint無視を解除
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    latestAgreedVersion = agreedVersions.split(",").sort((a, b) => {
      return a < b ? 1 : -1;
    })[0];
  }

  return authState === AuthState.SignedIn &&
    user &&
    resultJson &&
    fileData.result ? (
    <UserContextProvider>
      {/* ToDo 利用規約の同意画面を利用する場合コメントアウトを解除 */}
      {/* {fileData.result.split(",")[0] !== latestAgreedVersion.split("/")[0] ? (
        <Admin
          dataProvider={dataProvider}
          layout={TermsLayout}
          i18nProvider={i18nProvider}
          customReducers={{ theme: themeReducer }}
        >
          <Resource name="terms" {...terms} />
        </Admin>
      ) : ( */}
      <Admin
        dataProvider={dataProvider}
        layout={Layout}
        dashboard={Dashboard}
        i18nProvider={i18nProvider}
        customReducers={{ theme: themeReducer }}
        customRoutes={customRoutes}
      >
        <Resource name="projects" {...projects} />
        <Resource name="news" {...news} />
        <Resource name="legals" {...legals} />
        <Resource name="categorys" {...categorys} />
        <Resource name="legalsmap" {...legalsmap} />
        <Resource name="users" {...users} />
        <Resource name="division" />
        <Resource name="requirement" />
        <Resource name="filenotfound" {...filenotfound} />
      </Admin>
      {/* )} */}
    </UserContextProvider>
  ) : (
    <AmplifyAuthenticator>
      {
        <AmplifySignIn
          slot="sign-in"
          formFields={[
            {
              type: "email",
              label: "メールアドレス *",
              placeholder: "メールアドレスを入力して下さい",
              required: true,
            },
            {
              type: "password",
              label: "パスワード *",
              placeholder: "パスワードを入力して下さい",
              required: true,
            },
          ]}
          hideSignUp={true}
        />
      }
    </AmplifyAuthenticator>
  );
};

export default App;
