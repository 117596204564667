import { FilterListItem, useQueryWithStore } from "react-admin";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import { Category } from "../types";
import { useState } from "react";
import AccordionFillterList from "./AccordionFillterList";

const CategoryFilterListItem = () => {
  const [open, setOpen] = useState<boolean>(true);

  const handleToggle = () => {
    setOpen((prevState) => (prevState ? false : true));
  };

  const { loaded, data: categorys } = useQueryWithStore({
    type: "getList",
    resource: "categorys",
    payload: {
      sort: { field: "category_code", order: "ASC" },
      pagination: { page: 1, perPage: 100 },
    },
  });

  if (!loaded) return null;

  return (
    <AccordionFillterList
      handleToggle={handleToggle}
      name="resources.legals.filters.category"
      icon={<AccountTreeIcon />}
      isOpen={open}
    >
      {categorys
        ? categorys.map((record: Category) => (
            <FilterListItem
              key={record.category_code}
              label={record.category_name}
              value={{ category: record.category_name }}
            />
          ))
        : null}
    </AccordionFillterList>
  );
};

export default CategoryFilterListItem;
