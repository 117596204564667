import MenuBookIcon from "@material-ui/icons/MenuBook";

import { useQueryWithStore, useTranslate } from "react-admin";

import CardWithIcon from "./CardWithIcon";

interface Props {
  title: string;
}

const getOneWeekBeforeDate = () => {
  let dt = new Date();
  dt.setDate(dt.getDate() - 7);
  var yyyy = dt.getFullYear();
  var mm = ("0" + (dt.getMonth() + 1)).slice(-2);
  var dd = ("0" + dt.getDate()).slice(-2);

  return yyyy + "-" + mm + "-" + dd;
};

const WeeklyUpdateLegalCard = (props: Props) => {
  const { title } = props;
  const translate = useTranslate();

  const result = useQueryWithStore({
    type: "getList",
    resource: "legals",
    payload: {
      sort: { field: "publishCriteriaDate", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
      filter: {
        publishCriteriaDate_gte: getOneWeekBeforeDate(),
      },
    },
  });


  if (!result.loaded) return null;

  return (
    <CardWithIcon
      to="/legals"
      icon={MenuBookIcon}
      title={translate(title)}
      subtitle={String(result.data.length)}
    />
  );
};

export default WeeklyUpdateLegalCard;
