import * as React from "react";
import { Fragment, useCallback, ReactElement } from "react";
import { Datagrid, DateField, List, ListProps, TextField } from "react-admin";
import { Route, RouteChildrenProps, useHistory } from "react-router-dom";
import { Drawer, useMediaQuery, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import LegalListAside from "./LegalListAside";
import LegalEdit from "./LegalEdit";
import NameField from "./NameField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  nb_commands: { color: "purple" },
  hiddenOnSmallScreens: {
    display: "table-cell",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  drawerPaper: {
    zIndex: 100,
  },
  data_grid: {
    backgroundColor: "#212121",
  },
  list_name: {
    width: "30%",
  },
  list_cnName: {
    width: "30%",
  },
  list_category: {
    width: "20%",
  },
  list_announceDate: {
    width: "10%",
  },
  list_entryDate: {
    width: "10%",
  },
  list: {
    width: "100%",
  },
}));

const rowStyle = (record, index) => {
  if (record.status === 1) {
    return {
      borderLeftColor: "#ddefe8",
      borderLeftWidth: 10,
      borderLeftStyle: "solid",
    };
  } else if (record.status === 2) {
    return {
      borderLeftColor: "#6fc2b4",
      borderLeftWidth: 10,
      borderLeftStyle: "solid",
    };
  } else if (record.status === 3) {
    return {
      borderLeftColor: "#007680",
      borderLeftWidth: 10,
      borderLeftStyle: "solid",
    };
  } else if (record.status === 4) {
    return {
      borderLeftColor: "#53565A",
      borderLeftWidth: 10,
      borderLeftStyle: "solid",
    };
  }
};

const LegalList = (props: ListProps): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const handleClose = useCallback(() => {
    history.push("/legals");
  }, [history]);

  return (
    <div className={classes.root}>
      <Route path="/legals/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );
          return (
            <Fragment>
              <List
                {...props}
                filters={isSmall ? undefined : undefined}
                sort={{ field: "id", order: "ASC" }}
                perPage={25}
                aside={<LegalListAside />}
                exporter={false}
                bulkActionButtons={false}
                actions={false}
                className={classes.list}
              >
                <Datagrid
                  optimized
                  rowClick="edit"
                  rowStyle={rowStyle}
                  classes={{ headerCell: classes.data_grid }}
                >
                  <NameField
                    source="name"
                    label="resources.legals.fields.name"
                    sortBy="id"
                  />
                  <TextField
                    source="cnName"
                    label="resources.legals.fields.cnname"
                  />
                  <TextField
                    source="category"
                    label="resources.legals.fields.category"
                  />
                  <DateField
                    source="announceDate"
                    label="resources.legals.fields.announceDate"
                  />
                  <DateField
                    source="entryDate"
                    label="resources.legals.fields.entrydate"
                  />
                </Datagrid>
              </List>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {isMatch ? (
                  <LegalEdit
                    id={(match as any).params.id}
                    onCancel={handleClose}
                    resource={props.resource}
                    basePath={props.basePath}
                  />
                ) : null}
              </Drawer>
            </Fragment>
          );
        }}
      </Route>
    </div>
  );
};

export default LegalList;
