import * as React from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { AppBar, useQueryWithStore, useTranslate } from "react-admin";
import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useAuth from "../hook/useAuth";
import useFileDownload from "../hook/useFileDownload";

const useStyles = makeStyles({
  spacer: {
    flex: 1,
  },
  privacy: {
    marginRight: "1rem",
    cursor: "pointer",
  },
});

const CustomAppBar = (props: any) => {
  const translate = useTranslate();
  const classes = useStyles();
  const user = useAuth();
  const [fileDownload] = useFileDownload(user.cognitoUser);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const currentVersion = useQueryWithStore({
    type: "getOne",
    resource: "terms",
    payload: { id: 0 },
  });

  return (
    <AppBar {...props} elevation={1} userMenu={false} toolbar={<></>}>
      <Typography variant="h5">{translate("appbar.title")}</Typography>
      <span className={classes.spacer} />
      <Typography variant="body1" component="p" className={classes.privacy}>
        <Link
          color="textSecondary"
          onClick={() =>
            window.open(
              "https://www2.deloitte.com/jp/ja/footerlinks1/privacy.html?icid=bottom_privacy",
              "_blank"
            )
          }
        >
          {translate("appbar.privacy_policy")}
        </Link>
      </Typography>
      <Button onClick={handleClick}>{translate("appbar.term_name")}</Button>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            fileDownload(currentVersion?.data?.jpTermFilePath);
          }}
        >
          {translate("appbar.terms.jp")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            fileDownload(currentVersion?.data?.cnTermFilePath);
          }}
        >
          {translate("appbar.terms.cn")}
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

export default CustomAppBar;
