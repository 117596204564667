import { useEffect, useMemo, useState } from "react";
import axios from "axios";

type OperationId = "create" | "delete" | "list";

export const useUserQuery = function (
  user: any,
  operationId: OperationId,
  pramQueryVariable: object = {}
) {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<Array<any>>();
  const [error, setError] = useState<Error>();

  const idToken = user?.signInUserSession?.idToken.jwtToken;
  const accessToken = user?.signInUserSession?.accessToken.jwtToken;
  const isAdmin = !user?.attributes
    ? false
    : user.attributes["custom:admin"] === "1";

  const operationIdMome = operationId;

  const pramQueryVariableMome = useMemo(
    () => pramQueryVariable,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      authorization: idToken,
      "x-access-token": accessToken,
    }),
    [idToken, accessToken]
  );

  const API_URL = process.env.REACT_APP_APIGATEWAY_ENDPOINT + "/prod/user";

  useEffect(() => {
    const load = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          API_URL + "/" + operationId,
          pramQueryVariableMome,
          { headers }
        );
        if (response) {
          setResult(response.data);
        } else {
          throw Error(response);
        }
      } catch (e) {
        console.error(e);
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    };
    if (idToken !== undefined && accessToken !== undefined && isAdmin) {
      load();
    }
  }, [
    API_URL,
    idToken,
    accessToken,
    headers,
    operationId,
    operationIdMome,
    pramQueryVariableMome,
    user,
    isAdmin,
  ]);

  return { isLoading, error, result };
};

export default useUserQuery;
