import { useEffect, useMemo, useState } from "react";
import axios from "axios";

export const useFileData = (user: any, path: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [result, setResult] = useState<string>();

  const API_URL = process.env.REACT_APP_APIGATEWAY_ENDPOINT + "/prod/file";

  const idToken = useMemo(() => user?.signInUserSession?.idToken.jwtToken, [
    user,
  ]);
  const accessToken = useMemo(
    () => user?.signInUserSession?.accessToken.jwtToken,
    [user]
  );

  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      authorization: idToken,
      "x-access-token": accessToken,
    }),
    [idToken, accessToken]
  );

  useEffect(() => {
    const load = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const responseUrl = await axios.get(API_URL, {
          headers,
          params: {
            path: path,
          },
        });
        if (responseUrl) {
          const responseFile = await axios.get(responseUrl.data);
          if (responseFile) {
            setResult(responseFile.data);
          } else {
            throw Error(responseFile);
          }
        } else {
          throw Error(responseUrl);
        }
      } catch (e) {
        console.error(e);
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    };

    if (idToken !== undefined && accessToken !== undefined) {
      load();
    }
  }, [API_URL, headers, accessToken, idToken, path]);

  return { isLoading, error, result };
};

export default useFileData;
