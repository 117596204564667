import {
  useEditController,
  EditContextProvider,
  useTranslate,
  SimpleForm,
  EditProps,
  useLocale,
  TextField,
  TextInput,
} from "react-admin";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import LegalEditToolbar from "./LegalEditToolbar";
import { Legal } from "../types";
import StatusField from "./StatusField";
import RequirementList from "./RequirementList";
import ProjectList from "./ProjectList";
import ContentField from "./ContentField";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
  stepper: {
    background: "none",
    border: "none",
    marginLeft: "0.3em",
  },
}));

interface Props extends EditProps {
  onCancel: () => void;
}

const LegalEdit = ({ onCancel, ...props }: Props) => {
  const classes = useStyles();
  const controllerProps = useEditController<Legal>(props);
  const translate = useTranslate();
  const locale = useLocale();
  if (!controllerProps.record) {
    return null;
  }
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">
          {translate("resources.legals.detail")}
        </Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <EditContextProvider value={controllerProps}>
        <SimpleForm
          className={classes.form}
          basePath={controllerProps.basePath}
          record={controllerProps.record}
          save={controllerProps.save}
          version={controllerProps.version}
          redirect="list"
          resource="reviews"
          toolbar={<LegalEditToolbar />}
        >
          <TextField
            source="name"
            fullWidth
            label="resources.legals.fields.name"
          />
          <TextField
            source="division"
            label="resources.legals.fields.division"
            formClassName={classes.inlineField}
          />
          <StatusField
            source="status"
            label="resources.status.name"
            formClassName={classes.inlineField}
          />
          <TextInput
            source="description"
            label="resources.legals.fields.description"
            multiline
            fullWidth
          />

          <ContentField label={"resources.legals.fields.originalContent"} />
          <ContentField label={"resources.legals.fields.japaneseContent"} />
          <ContentField
            label={"resources.legals.fields.interpretationContent"}
          />
          <RequirementList />
          <ProjectList />
        </SimpleForm>
      </EditContextProvider>
      {controllerProps.record.history.length !== 0 && (
        <Box m="0 0 1em 1em">
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {translate("resources.customers.fieldGroups.history")}
              </Typography>
              <Stepper
                orientation="vertical"
                classes={{ root: classes.stepper }}
              >
                {controllerProps.record.history.map((event, index) => (
                  <Step key={index} expanded active completed>
                    <StepLabel StepIconComponent={CloseIcon}>
                      {new Date(event.update_date).toLocaleString(locale, {
                        weekday: "long",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </StepLabel>
                    <StepContent>
                      <Typography>{event.comment}</Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </CardContent>
          </Card>
        </Box>
      )}
    </div>
  );
};

export default LegalEdit;
