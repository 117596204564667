import {
  useEditController,
  EditContextProvider,
  useTranslate,
  SimpleForm,
  EditProps,
  TextInput,
} from "react-admin";
import { IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ProjectEditToolbar from "./ProjectEditToolbar";
import { Project } from "../types";
import RegulationList from "./RegulationList";
import { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
}));

interface Props extends EditProps {
  onCancel: () => void;
}

const ProjectEdit = ({ onCancel, ...props }: Props) => {
  const classes = useStyles();
  const controllerProps = useEditController<Project>(props);
  const translate = useTranslate();
  const [projectName, setProjectName] = useState(
    controllerProps.record?.project_name
  );
  const [description, setDescription] = useState(
    controllerProps.record?.description
  );

  useEffect(() => {
    setProjectName(controllerProps.record?.project_name);
    setDescription(controllerProps.record?.description);
  }, [
    setProjectName,
    setDescription,
    controllerProps.record?.project_name,
    controllerProps.record?.description,
  ]);

  if (!controllerProps.record) {
    return null;
  }
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">
          {translate("resources.projects.detail")}
        </Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <EditContextProvider value={controllerProps}>
        <SimpleForm
          className={classes.form}
          basePath={controllerProps.basePath}
          record={controllerProps.record}
          save={controllerProps.save}
          version={controllerProps.version}
          redirect="list"
          resource="reviews"
          toolbar={
            <ProjectEditToolbar
              projectName={projectName}
              description={description}
            />
          }
        >
          <TextInput
            source="project_name"
            fullWidth
            label="resources.projects.fields.name"
            onChange={(e) => {
              setProjectName(e.target.value);
            }}
          />
          <TextInput
            source="description"
            label="resources.projects.fields.description"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            multiline
            fullWidth
          />
          <RegulationList />
        </SimpleForm>
      </EditContextProvider>
    </div>
  );
};

export default ProjectEdit;
