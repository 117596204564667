import { TranslationMessages } from "react-admin";
import englishMessages from "ra-language-english";
// import japaneseMessages from "@bicstone/ra-language-japanese";

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  appbar: {
    title: "Global Technology Regulation",
    term_name: "Terms of Use",
    terms: {
      jp: "JP",
      cn: "CN",
    },
    privacy_policy: "Privacy Policy",
  },
  pos: {
    search: "Search",
    configuration: "Configuration",
    language: "Language",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    dashboard: {
      welcome: {
        title: "Global Technology Regulation",
        subtitle1:
          "This service enables efficient collection and analysis of regulatory information, which is the first step in complying with regulations.",
        subtitle2:
          "In addition to the original and translated texts of laws and regulations, summaries of important laws and regulations, and explanations of requirements accumulated by the Deloitte, we also provide information on legal trends on a regular basis.",
      },
      weekly_update_legal: "Weekly Update Legals",
      weekly_update_legal_empty: "No updates within a week.",
      month_history: "30 Day Revenue History",
      new_orders: "New Orders",
      pending_reviews: "Pending Reviews",
      all_reviews: "See all reviews",
      news: "News",
      all_news: "Display all",
      follows: "Follows",
      all_follows: "Display all",
      favorites: "Favorites",
      all_favorites: "Display all",
      categorys: "Categorys",
      all_categorys: "Display all",
      keywords: "Keywords",
      all_keywords: "Display all",
      topics: "Topics",
      all_topics: "Display all",
      statuses: "Legal Statuses",
      all_statuses: "Display all",
      pending_orders: "Pending Orders",
      order: {
        items:
          "by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items",
      },
      legals: {
        name: "name",
        announceDate: "announceDate",
        entryDate: "entryDate",
        status: "legalStatus",
      },
    },
    menu: {
      legals: "Regulations",
      settings: "Settings",
    },
  },
  resources: {
    common: {
      yes: "Yes",
      no: "No",
    },
    news: {
      name: "お知らせ",
      fields: {
        id: "ID",
        content: "内容",
        create: "発行日",
        lastupdate: "更新日",
      },
      empty: "まだお知らせがありません。",
    },
    projects: {
      name: "お気に入り",
      detail: "お気に入り詳細",
      data: {
        1: "製品Ａの中国展開プロジェクト",
        2: "製品Ｂの中国展開プロジェクト",
      },
      fields: {
        id: "ID",
        name: "名称",
        description: "説明",
        regal: "関連法令",
      },
      save: {
        label: "保存",
      },
      delete: {
        label: "削除",
        title: "削除確認",
        message: "お気に入りを削除しますか？",
      },
      dialog: {
        title: "保存確認",
        message: "お気に入りを保存しますか？",
      },
      snackbar: {
        success: "更新が成功しました。",
        error: "更新に失敗しました。",
      },
      create: {
        create: {
          create: "お気に入り作成",
          cansel: "キャンセル",
          name: "お気に入り名称",
          description: "お気に入り説明",
        },
      },
      createRelation: {
        createAreaName: "お気に入り作成",
        editRelationAreaName: "お気に入り編集",
      },
      empty: {
        empty: "まだお気に入りが作成されていません。",
        invite: "お気に入りを作成しますか？",
      },
    },
    follows: {
      name: "フォロー",
      data: {
        1: "調査中のレギュレーション",
        2: "監視が必要なレギュレーション",
      },
    },
    categorys: {
      name: "カテゴリー",
    },
    users: {
      name: "ユーザ",
      detail: "ユーザ詳細",
      fields: {
        id: "ID",
        name: "メールアドレス",
        create: "作成日",
        lastupdate: "更新日",
      },
      delete: {
        label: "ユーザ削除",
        title: "削除確認",
        message: "ユーザを削除しますか？",
      },
      create: {
        create: "ユーザ作成",
      },
      snackbar: {
        success: "更新が成功しました。",
        error: "更新に失敗しました。",
      },
    },
    legals: {
      name: "法令検索",
      detail: "法令詳細",
      fields: {
        id: "ID",
        name: "名称(日本語)",
        cnname: "名称(中国語)",
        category: "カテゴリー",
        requirement: "主要要件",
        topic: "トピック",
        status: "法令ステータス",
        entrydate: "施行日",
        create: "登録日",
        lastupdate: "最終更新日",
        rate: "重要度",
        description: "説明",
        division: "規約区分",
        announceDate: "公布日",
        originalContent: "原文",
        japaneseContent: "翻訳",
        interpretationContent: "要約",
        project: "お気に入り",
      },
      filters: {
        last_visited: "最終更新日",
        today: "当日",
        this_week: "当週",
        last_week: "先週",
        this_month: "当月",
        last_month: "先月",
        earlier: "先月～当日",
        project: "お気に入り",
        follow: "フォロー",
        category: "カテゴリ",
        keyword: "キーワード",
        topic: "トピック",
        status: "法令ステータス",
        group: "Segment",
        req: "主要要件",
        announceDate: "公布日",
      },
    },
    customers: {
      name: "Customer |||| Customers",
      fields: {
        commands: "Orders",
        first_seen: "First seen",
        groups: "Segments",
        last_seen: "Last seen",
        last_seen_gte: "Visited Since",
        name: "Name",
        total_spent: "Total spent",
        password: "Password",
        confirm_password: "Confirm password",
        stateAbbr: "State",
      },
      filters: {
        last_visited: "Last visited",
        today: "Today",
        this_week: "This week",
        last_week: "Last week",
        this_month: "This month",
        last_month: "Last month",
        earlier: "Earlier",
        has_ordered: "Has",
        has_newsletter: "Has newsletter",
        group: "Segment",
      },
      fieldGroups: {
        identity: "Identity",
        address: "Address",
        stats: "Stats",
        history: "History",
        password: "Password",
        change_password: "Change Password",
      },
      page: {
        delete: "Delete Customer",
      },
      errors: {
        password_mismatch:
          "The password confirmation is not the same as the password.",
      },
    },
    commands: {
      name: "Order |||| Orders",
      amount: "1 order |||| %{smart_count} orders",
      title: "Order %{reference}",
      fields: {
        basket: {
          delivery: "Delivery",
          reference: "Reference",
          quantity: "Quantity",
          sum: "Sum",
          tax_rate: "Tax Rate",
          taxes: "Tax",
          total: "Total",
          unit_price: "Unit Price",
        },
        address: "Address",
        customer_id: "Customer",
        date_gte: "Passed Since",
        date_lte: "Passed Before",
        nb_items: "Nb Items",
        total_gte: "Min amount",
        status: "Status",
        returned: "Returned",
      },
      section: {
        order: "Order",
        customer: "Customer",
        shipping_address: "Shipping Address",
        items: "Items",
        total: "Totals",
      },
    },
    categories: {
      name: "Category |||| Categories",
      fields: {
        products: "Products",
      },
      data: {
        1: "CS関連法令・弁法",
        2: "プライバシー関連法令・弁法",
        3: "CS関連標準・ガイドライン",
        4: "CS・プライバシー関連以外",
        5: "プライバシー関連標準・ガイドライン",
      },
    },
    requirement: {
      name: "主要要件",
      data: {
        1: "等級保護",
        2: "ネットワーク製品・安全認証",
        3: "緊急対応",
        4: "重要インフラ事業者",
        5: "データ越境制限",
        6: "個人情報保護",
        7: "その他_全般",
      },
    },
    topics: {
      name: "トピック",
      data: {
        1: "金融",
        2: "インターネット",
      },
    },
    status: {
      name: "法令ステータス",
      data: {
        1: "提案",
        2: "草案",
        3: "施行中",
        4: "廃止",
      },
    },
    division: {
      name: "規約区分",
    },
    legalsmap: {
      name: "俯瞰図",
      legend: "凡例",
    },
    terms: {
      title: "Terms of Service",
      link: "View Terms of Service",
      subtitle:
        "Please read the Terms of Service and if you agree, check the box below to continue.",
      checkbox: "I agree to the Terms of Service",
      nextbutton: "Continue",
      snackbar: "Update failed. Please contact the administrator.",
    },
    filenotfound: {
      title: "File not found",
      subtitle:
        "File download failed. If this screen appears repeatedly, please contact the administrator.",
    },
  },
};

export default customEnglishMessages;
