import { useRef } from "react";
import {
  Create,
  Link,
  SimpleForm,
  TextInput,
  Toolbar,
  useQueryWithStore,
  useRefresh,
} from "react-admin"; // eslint-disable-line import/no-unresolved
import Button from "@material-ui/core/Button";
import useUserEditQuery from "../hook/useUserEditQuery";
import useAuth from "../hook/useAuth";
import { useHistory } from "react-router-dom";
import { getUserList } from "./UserUtil";
import { makeStyles } from "@material-ui/core/styles";

const UserCreate = ({ ...props }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "40%",
    },
  }));

  const classes = useStyles();
  const user = useAuth();
  const usernameEl = useRef<HTMLInputElement>(null!);
  const temporaryPasswordEl = useRef<HTMLInputElement>(null!);
  const history = useHistory();
  const refresh = useRefresh();
  const { loaded, data: users } = useQueryWithStore({
    type: "getList",
    resource: "users",
    payload: {
      sort: { field: "id", order: "ASC" },
      pagination: { page: 1, perPage: 100 },
    },
  });
  const [userCreate] = useUserEditQuery();

  let errors = {};
  const validateUserCreation = (values) => {
    const mailRegexp = /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    const passwordRegexp = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!-/:-@[-`{-~])[a-zA-Z\d!-/:-@[-`{-~]{10,}$/;

    errors = {};
    if (user?.userInfo?.["attributes"]?.["custom:userLimit"] <= users.length) {
      errors["username"] = "ユーザー数が契約上限に達しています。";
    } else {
      if (!values.username) {
        errors["username"] = "メールアドレスは必須です";
      } else {
        if (!mailRegexp.test(values.username)) {
          errors["username"] = "メールアドレスを入力してください";
        } else {
          users.forEach((item) => {
            if (item.name === values.username) {
              errors["username"] = "登録済みのメールアドレスです";
            }
          });
        }
      }
    }

    if (!values.temporaryPassword) {
      errors["temporaryPassword"] = "仮パスワードは必須です";
    } else {
      if (!passwordRegexp.test(values.temporaryPassword)) {
        errors["temporaryPassword"] =
          "英数小文字、英数大文字、数字、記号の4種を全て利用した10文字以上のパスワードを入力してください";
      }
    }

    return errors;
  };
  if (!loaded) return null;

  const handleUserCreate = (): void => {
    if (Object.keys(errors).length === 0) {
      userCreate(
        "create",
        {
          username: usernameEl.current.value,
          temporaryPassword: temporaryPasswordEl.current.value,
        },
        user?.cognitoUser?.signInUserSession?.idToken?.jwtToken,
        user?.cognitoUser?.signInUserSession?.accessToken?.jwtToken
      ).then(() => {
        getUserList(user, refresh);
        history.push("/users");
      });
    }
  };

  const UserToolbar = (props) => (
    <Toolbar {...props} style={{ paddingLeft: 16, paddingRight: 16 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUserCreate}
        style={{ marginTop: 10, marginRight: 10, marginBottom: 10 }}
      >
        ユーザ作成
      </Button>
      <Link to="/users">
        <Button variant="outlined" color="primary" style={{ margin: 10 }}>
          キャンセル
        </Button>
      </Link>
    </Toolbar>
  );

  return (
    <Create {...props}>
      <SimpleForm validate={validateUserCreation} toolbar={<UserToolbar />}>
        <TextInput
          source="username"
          label="メールアドレス"
          inputRef={usernameEl}
          className={classes.root}
        />
        <TextInput
          source="temporaryPassword"
          label="仮パスワード"
          inputRef={temporaryPasswordEl}
          className={classes.root}
        />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
