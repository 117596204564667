import { useCallback, useMemo, useState } from "react";
import axios from "axios";

export const useFileDownload = function (user: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const API_URL = process.env.REACT_APP_APIGATEWAY_ENDPOINT + "/prod/file";

  const idToken = useMemo(
    () => user?.signInUserSession?.idToken.jwtToken,
    [user]
  );
  const accessToken = useMemo(
    () => user?.signInUserSession?.accessToken.jwtToken,
    [user]
  );

  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      authorization: idToken,
      "x-access-token": accessToken,
    }),
    [idToken, accessToken]
  );

  const fileDownload = useCallback(
    async (storagePath: string): Promise<void> => {
      setIsLoading(true);
      try {
        const response = await axios.get(API_URL, {
          headers,
          params: {
            path: storagePath,
          },
        });
        if (response) {
          fetch(response.data).then((res) =>
            res.ok ? window.open(response.data) : window.open("/#/filenotfound")
          );
        } else {
          throw Error(response);
        }
      } catch (e) {
        console.error(e);
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [API_URL, headers]
  );

  return [fileDownload, { isLoading, error }] as const;
};

export default useFileDownload;
