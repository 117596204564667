import { useTranslate, FieldProps } from "react-admin";
import { Legal } from "../types";
import Typography from "@material-ui/core/Typography";

const StatusField = ({ record }: FieldProps<Legal>) => {
  const translate = useTranslate();
  const translateKey = "resources.status.data." + record?.status;
  return record ? (
    <Typography component="span" variant="body1">
      {record.status && translate(translateKey)}
    </Typography>
  ) : null;
};

StatusField.defaultProps = {
  addLabel: true,
  label: "resources.legals.fields.topic",
};

export default StatusField;
