import * as React from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useTranslate, useQueryWithStore } from "react-admin";

import { News } from "../types";
import useFileDownload from "../hook/useFileDownload";
import useAuth from "../hook/useAuth";

const NewsList = () => {
  const translate = useTranslate();
  const user = useAuth();
  const [fileDownload] = useFileDownload(user.cognitoUser);
  const classes = useStyles();

  const { loaded, data: news } = useQueryWithStore({
    type: "getList",
    resource: "news",
    payload: {
      sort: { field: "create", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
    },
  });

  if (!loaded) return null;

  return (
    <Box flexGrow="1" style={{ marginTop: "20px" }}>
      <Box flexGrow="1">
        <Typography variant="h6" component="h2" gutterBottom>
          {translate("pos.dashboard.news")}
        </Typography>
      </Box>
      <List className={classes.list}>
        {news
          ? news.map((record: News, index) =>
              index < 4 ? (
                <ListItem
                  button={record.path}
                  key={record.id}
                  style={{
                    height: "25px",
                  }}
                  onClick={() => record.path && fileDownload(record.path)}
                >
                  <ListItemIcon style={{ marginRight: "10px" }}>
                    {DateTime.fromFormat(
                      record.create,
                      "yyyy-LL-dd HH:mm:ss"
                    ).toFormat("yyyy-LL-dd")}
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      height: "25px",
                      overflow: "clip",
                      marginBottom: "0px",
                    }}
                    primary={record.content}
                  />
                </ListItem>
              ) : null
            )
          : null}
      </List>
      <Box flexGrow="1" mt={1}>
        &nbsp;
      </Box>
      <Button
        className={classes.link}
        component={Link}
        to="/news"
        size="small"
        color="primary"
      >
        <Box p={1} className={classes.linkContent}>
          {translate("pos.dashboard.all_news")}
        </Box>
      </Button>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  link: {
    borderRadius: 0,
  },
  linkContent: {
    color: theme.palette.primary.main,
  },
  list: {
    height: "60px",
  },
}));

export default NewsList;
