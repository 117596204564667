import * as React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  ReduxState,
} from "react-admin";

import legals from "../legals";
import legalsmap from "../legalsmap";
import { AppState } from "../types";
import FlagIcon from "@material-ui/icons/Flag";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import useAuth from "../hook/useAuth";
import users from "../users";

const Menu = ({ dense = false }: MenuProps) => {
  const translate = useTranslate();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change
  const classes = useStyles();
  const user = useAuth();
  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      <DashboardMenuItem />
      {/* @ts-ignore */}
      <MenuItemLink
        to={{
          pathname: "/news",
          state: { _scrollToTop: true },
        }}
        primaryText={translate(`resources.news.name`, {
          smart_count: 2,
        })}
        leftIcon={<AnnouncementIcon />}
        dense={dense}
      />
      {/* @ts-ignore */}
      <MenuItemLink
        to={{
          pathname: "/projects",
          state: { _scrollToTop: true },
        }}
        primaryText={translate(`resources.projects.name`, {
          smart_count: 2,
        })}
        leftIcon={<FlagIcon />}
        dense={dense}
      />
      {/* @ts-ignore */}
      <MenuItemLink
        to={{
          pathname: "/legals",
          state: { _scrollToTop: true },
        }}
        primaryText={translate(`resources.legals.name`, {
          smart_count: 2,
        })}
        leftIcon={<legals.icon />}
        dense={dense}
      />
      {/* @ts-ignore */}
      <MenuItemLink
        to={{
          pathname: "/legalsmap",
          state: { _scrollToTop: true },
        }}
        primaryText={translate(`resources.legalsmap.name`, {
          smart_count: 2,
        })}
        leftIcon={<legalsmap.icon />}
        dense={dense}
      />
      {user?.userInfo?.["attributes"]?.["custom:admin"] === "1" && (
        /* @ts-ignore */
        <MenuItemLink
          to={{
            pathname: "/users",
            state: { _scrollToTop: true },
          }}
          primaryText={translate(`resources.users.name`, {
            smart_count: 2,
          })}
          leftIcon={<users.icon />}
          dense={dense}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 210,
  },
  closed: {
    width: 55,
  },
}));

export default Menu;
