import * as React from "react";
import {
  useEditController,
  EditContextProvider,
  useTranslate,
  SimpleForm,
  EditProps,
  TextField,
  Toolbar,
  useRefresh,
  DateField,
} from "react-admin";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { User } from "../types";
import useUserEditQuery from "../hook/useUserEditQuery";
import { getUserList } from "./UserUtil";
import useAuth from "../hook/useAuth";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  form: {
    [theme.breakpoints.up("xs")]: {
      width: 600,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginTop: -30,
    },
  },
  inlineField: {
    display: "inline-block",
    width: "50%",
  },
  stepper: {
    background: "none",
    border: "none",
    marginLeft: "0.3em",
  },
}));

interface Props extends EditProps {
  user;
}

const UserEdit = ({ user, ...props }: Props) => {
  const [userDelete] = useUserEditQuery();
  const history = useHistory();
  const refresh = useRefresh();
  const userAuth = useAuth();
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleCloseDelete = () => setOpenDelete(false);

  const handleUserDelete = (): void => {
    setOpenDelete(false);
    userDelete(
      "delete",
      {
        username: props.id,
      },
      user?.cognitoUser?.signInUserSession?.idToken?.jwtToken,
      user?.cognitoUser?.signInUserSession?.accessToken?.jwtToken
    )
      .then(() => {
        getUserList(user, refresh);
        history.push("/users?openSnackbar=1");
      })
      .catch((e) => {
        console.error(e);
        history.push("/users?openSnackbar=2");
      });
  };

  const UserToolbar = (props) => {
    return userAuth?.userInfo?.["username"] !== props.id ? (
      <Toolbar
        {...props}
        style={{
          backgroundColor: "#303030",
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenDelete(true)}
        >
          {translate("resources.users.delete.label")}
        </Button>
        <Dialog open={openDelete}>
          <DialogTitle>{translate("resources.users.delete.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {translate("resources.users.delete.message")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleUserDelete}
              variant="contained"
              color="primary"
            >
              {translate("resources.common.yes")}
            </Button>
            <Button
              onClick={handleCloseDelete}
              variant="outlined"
              color="primary"
            >
              {translate("resources.common.no")}
            </Button>
          </DialogActions>
        </Dialog>
      </Toolbar>
    ) : null;
  };

  const classes = useStyles();
  const controllerProps = useEditController<User>(props);
  const translate = useTranslate();
  if (!controllerProps.record) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">
          {translate("resources.users.detail")}
        </Typography>
      </div>
      <EditContextProvider value={controllerProps}>
        <SimpleForm
          className={classes.form}
          basePath={controllerProps.basePath}
          record={controllerProps.record}
          save={controllerProps.save}
          version={controllerProps.version}
          redirect="list"
          resource="reviews"
          toolbar={<UserToolbar {...props} />}
        >
          <TextField source="name" label="resources.users.fields.name" />
          <DateField source="create" label="resources.users.fields.create" />
          <DateField
            source="lastupdate"
            label="resources.users.fields.lastupdate"
          />
        </SimpleForm>
      </EditContextProvider>
    </div>
  );
};

export default UserEdit;
