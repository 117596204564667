import * as React from "react";
import { Fragment, ReactElement, ReactNode } from "react";
import {
  List,
  MenuItem,
  ListItemIcon,
  Typography,
  Collapse,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  icon: { minWidth: theme.spacing(5) },
  sidebarIsOpen: {
    "& a": {
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
      paddingLeft: theme.spacing(4),
    },
  },
  sidebarIsClosed: {
    "& a": {
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
      paddingLeft: theme.spacing(2),
    },
  },
}));

interface Props {
  handleToggle: () => void;
  icon: ReactElement;
  isOpen: boolean;
  name: string;
  children: ReactNode;
}

const AccordionFillterList = (props: Props) => {
  const { handleToggle, isOpen, name, icon, children } = props;
  const translate = useTranslate();
  const classes = useStyles();

  const header = (
    <MenuItem
      button
      onClick={handleToggle}
      style={{ paddingLeft: "0px", fontSize: "0.75rem" }}
    >
      <ListItemIcon className={classes.icon}>
        {isOpen ? <ExpandMore /> : icon}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {translate(name)}
      </Typography>
    </MenuItem>
  );

  return (
    <Fragment>
      {isOpen ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default AccordionFillterList;
