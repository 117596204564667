import { FilterListItem } from "react-admin";
import TimelineIcon from "@material-ui/icons/Timeline";
import { withStyles } from "@material-ui/core";
import AccordionFillterList from "./AccordionFillterList";
import { useState } from "react";

const ProposalFilter = withStyles((theme) => ({
  listItemText: {
    color: "#000000",
    margin: 0,
    backgroundColor: "#ddefe8",
  },
}))(FilterListItem);
const DraftFilter = withStyles((theme) => ({
  listItemText: {
    color: "#000000",
    margin: 0,
    backgroundColor: "#6fc2b4",
  },
}))(FilterListItem);
const ExecutionFilter = withStyles((theme) => ({
  listItemText: {
    color: "#ffffff",
    margin: 0,
    backgroundColor: "#007680",
  },
}))(FilterListItem);
const ClosedFilter = withStyles((theme) => ({
  listItemText: {
    color: "#ffffff",
    margin: 0,
    backgroundColor: "#53565A",
  },
}))(FilterListItem);

const StatusFilterListItem = () => {
  const [open, setOpen] = useState<boolean>(true);

  const handleToggle = () => {
    setOpen((prevState) => (prevState ? false : true));
  };
  return (
    <AccordionFillterList
      handleToggle={handleToggle}
      name="resources.legals.filters.status"
      icon={<TimelineIcon />}
      isOpen={open}
    >
      <ProposalFilter
        label="resources.status.data.1"
        value={{
          status: "1",
        }}
      />

      <DraftFilter
        label="resources.status.data.2"
        value={{
          status: "2",
        }}
      />
      <ExecutionFilter
        label="resources.status.data.3"
        value={{
          status: "3",
        }}
      />
      <ClosedFilter
        label="resources.status.data.4"
        value={{
          status: "4",
        }}
      />
    </AccordionFillterList>
  );
};
export default StatusFilterListItem;
