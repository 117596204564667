import LegalsMapIcon from "@material-ui/icons/Map";

import LegalsMapList from "./LegalsMap";

const legalsmap = {
  list: LegalsMapList,
  icon: LegalsMapIcon,
};

export default legalsmap;
