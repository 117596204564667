import { useEffect, useMemo, useState } from "react";
import axios from "axios";

export const useQuery = function (
  user: any,
  sqlid: string,
  pramSqlVariable: object = {}
) {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<Array<any>>();
  const [error, setError] = useState<Error>();

  const formatJson = (response) => {
    var newArray: Array<Object> = [];
    response.data.records.forEach((record, i) => {
      var row = {};
      record.forEach((item, j) => {
        if ("isNull" !== Object.keys(item)[0])
          row[response.data.columnMetadata[j].name as string] =
            item[Object.keys(item)[0]];
      });
      newArray.push(row);
    });
    return newArray;
  };

  const idToken = useMemo(
    () => user?.signInUserSession?.idToken.jwtToken,
    [user]
  );
  const accessToken = useMemo(
    () => user?.signInUserSession?.accessToken.jwtToken,
    [user]
  );

  const sqlidMome = useMemo(() => sqlid, [sqlid]);

  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      authorization: idToken,
      "x-access-token": accessToken,
    }),
    [idToken, accessToken]
  );

  const API_URL = process.env.REACT_APP_APIGATEWAY_ENDPOINT + "/prod/data";

  useEffect(() => {
    const load = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          API_URL,
          { sqlId: sqlidMome, sqlVariable: pramSqlVariable },
          { headers }
        );
        if (response) {
          setResult(await formatJson(response));
        } else {
          throw Error(response);
        }
      } catch (e) {
        console.error(e);
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    };
    if (idToken !== undefined && accessToken !== undefined) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken, accessToken, headers, sqlidMome]);

  return { isLoading, error, result };
};

export default useQuery;
