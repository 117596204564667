import * as React from "react";
import { Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import MuiToolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";

import {
  ToolbarProps,
  useDataProvider,
  useRefresh,
  useTranslate,
} from "react-admin";
import useMutation from "../hook/useMutation";
import useAuth from "../hook/useAuth";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "space-between",
  },
}));

interface ProjectEditToolbarProps extends ToolbarProps {
  projectName: string | undefined;
  description: string | undefined;
}

const ProjectEditToolbar = ({
  projectName,
  description,
  ...props
}: ProjectEditToolbarProps) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const user = useAuth();
  const history = useHistory();
  const refresh = useRefresh();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const { record, pristine } = props;
  const classes = useStyles();
  const [updateProjectMutation, updateProjectMutationResult] = useMutation(
    user.cognitoUser,
    "updateProject"
  );
  const [deleteProjectMutation, deleteProjectMutationResult] = useMutation(
    user.cognitoUser,
    "deleteProject"
  );
  React.useEffect(() => {
    setErrorSnackbar(
      !updateProjectMutationResult.error || !deleteProjectMutationResult.error
    );
  }, [
    setOpenSnackbar,
    setErrorSnackbar,
    updateProjectMutationResult.error,
    deleteProjectMutationResult.error,
  ]);

  const handleSave = () => {
    updateProjectMutation({
      projectName: projectName,
      description: description,
      projectId: record?.project_id,
      userId: user.cognitoUser?.username,
    }).then(() => {
      dataProvider.update("projects", {
        id: record!.id,
        data: { description: description, project_name: projectName },
        previousData: { id: record!.id },
      });
    });
    setOpen(false);
    setOpenSnackbar(true);
  };

  const handleDelete = () => {
    deleteProjectMutation({
      projectId: record?.project_id,
    }).then(() => {
      dataProvider
        .delete("projects", {
          id: record!.id,
          previousData: {
            id: record!.id,
            project_id: record?.project_id,
            project_name: record?.project_name,
            description: record?.description,
          },
        })
        .then(() => refresh());
    });
    setOpenDelete(false);
    setOpenSnackbar(true);
    history.push("/projects");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  if (!record) return null;

  return (
    <MuiToolbar
      className={classes.root}
      style={{ paddingLeft: 16, paddingRight: 16 }}
    >
      <Fragment>
        <Button
          variant="contained"
          style={{
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 6,
            paddingBottom: 6,
          }}
          color="primary"
          onClick={() => setOpen(true)}
          disabled={pristine}
        >
          {translate("resources.projects.save.label")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 6,
            paddingBottom: 6,
          }}
          onClick={() => setOpenDelete(true)}
        >
          {translate("resources.projects.delete.label")}
        </Button>
      </Fragment>
      <Dialog open={open}>
        <DialogTitle>
          {translate("resources.projects.dialog.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate("resources.projects.dialog.message")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} variant="contained" color="primary">
            {translate("resources.common.yes")}
          </Button>
          <Button onClick={handleClose} variant="outlined" color="primary">
            {translate("resources.common.no")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDelete}>
        <DialogTitle>
          {translate("resources.projects.delete.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate("resources.projects.delete.message")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} variant="contained" color="primary">
            {translate("resources.common.yes")}
          </Button>
          <Button
            onClick={handleCloseDelete}
            variant="outlined"
            color="primary"
          >
            {translate("resources.common.no")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={6000}
        open={openSnackbar}
        onClose={handleSnackbarClose}
        message={
          errorSnackbar
            ? translate("resources.projects.snackbar.success")
            : translate("resources.projects.snackbar.error")
        }
      />
    </MuiToolbar>
  );
};

export default ProjectEditToolbar;
