import { Box, Link, Typography } from "@material-ui/core";
import { FieldProps } from "react-admin";
import { Legal } from "../types";
import { makeStyles } from "@material-ui/core/styles";
import useAuth from "../hook/useAuth";

import axios from "axios";

const API_URL = process.env.REACT_APP_APIGATEWAY_ENDPOINT + "/prod/file";

const downloadContent = async (path, user) => {
  const headers = {
    "Content-Type": "application/json",
    authorization: user?.cognitoUser?.signInUserSession?.idToken.jwtToken,
  };
  try {
    const response = await axios.get(API_URL, {
      headers,
      params: {
        path: path,
      },
    });
    if (response) {
      fetch(response.data).then((res) =>
        res.ok ? window.open(response.data) : window.open("/#/filenotfound")
      );
    } else {
      throw Error(response);
    }
  } catch (e) {
    console.error(e);
  }
};

const useStyles = makeStyles({
  link: {
    cursor: "pointer",
  },
});

const getPriorityOrder = (label: string) => {
  switch (label) {
    case "resources.legals.fields.originalContent":
      return 1;
    case "resources.legals.fields.japaneseContent":
      return 2;
    case "resources.legals.fields.interpretationContent":
      return 3;
    default:
      return 0;
  }
};

const ContentField = ({ label, record }: FieldProps<Legal>) => {
  const classes = useStyles();
  const user = useAuth();
  const targetContentList = record?.content.filter(
    (content) => content.priority_order === getPriorityOrder(label as string)
  );

  return targetContentList ? (
    <Box>
      {targetContentList &&
        targetContentList.map((content, i) => {
          return content ? (
            <Box key={i}>
              <Typography
                component="span"
                variant="body1"
                className={classes.link}
              >
                <Link
                  color="primary"
                  onClick={() => downloadContent(content.storage_path, user)}
                >
                  {content.content_name}
                </Link>
              </Typography>
            </Box>
          ) : null;
        })}
    </Box>
  ) : null;
};

ContentField.defaultProps = {
  addLabel: true,
};

export default ContentField;
