import { FilterListItem, useQueryWithStore } from "react-admin";
import { Project } from "../types";
import FlagIcon from "@material-ui/icons/Flag";
import AccordionFillterList from "./AccordionFillterList";
import { useState } from "react";

const ProjectFilterListItem = () => {
  const [open, setOpen] = useState<boolean>(true);

  const handleToggle = () => {
    setOpen((prevState) => (prevState ? false : true));
  };

  const { loaded, data: projects } = useQueryWithStore({
    type: "getList",
    resource: "projects",
    payload: {
      sort: { field: "project_id", order: "ASC" },
      pagination: { page: 1, perPage: 100 },
    },
  });

  if (!loaded) return null;

  return (
    <AccordionFillterList
      handleToggle={handleToggle}
      name="resources.legals.filters.project"
      icon={<FlagIcon />}
      isOpen={open}
    >
      {projects
        ? projects.map((record: Project) => (
            <FilterListItem
              key={record.project_id}
              label={record.project_name}
              value={{ projectName: record.project_name }}
            />
          ))
        : null}
    </AccordionFillterList>
  );
};

export default ProjectFilterListItem;
