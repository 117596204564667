import { Box, Typography } from "@material-ui/core";
import { ReactElement } from "react";
import {
  Datagrid,
  DateField,
  List,
  ListProps,
  TextField,
  useTranslate,
} from "react-admin";
import { Route, RouteChildrenProps } from "react-router-dom";
import useAuth from "../hook/useAuth";
import { makeStyles } from "@material-ui/core/styles";
import useFileDownload from "../hook/useFileDownload";

const Empty = () => {
  const translate = useTranslate();

  return (
    <Box textAlign="center" m={2}>
      <Typography variant="h4" paragraph>
        {translate("resources.news.empty")}
      </Typography>
    </Box>
  );
};

const NewsList = (props: ListProps): ReactElement => {
  const useStyles = makeStyles((theme) => ({
    list_root: {
      width: "80%",
    },
    data_grid: {
      backgroundColor: "#212121",
    },
    list_content: {
      width: "90%",
    },
    list_create: {
      width: "10%",
    },
  }));

  const classes = useStyles();
  const user = useAuth();
  const [fileDownload] = useFileDownload(user.cognitoUser);

  if (!user) {
    return <></>;
  }

  return (
    <div>
      <Route path="/news/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => (
          <div>
            <List
              {...props}
              exporter={false}
              bulkActionButtons={false}
              classes={{ root: classes.list_root }}
              sort={{ field: "create", order: "DESC" }}
              empty={<Empty />}
            >
              <Datagrid
                classes={{ headerCell: classes.data_grid }}
                rowClick={(id, resource, record) => {
                  record.path && fileDownload(record.path);
                  return "";
                }}
                rowStyle={(record, index) => ({
                  cursor: record.path ? "pointer" : "default",
                })}
              >
                <DateField
                  source="create"
                  sortByOrder="DESC"
                  label="resources.news.fields.create"
                  headerClassName={classes.list_create}
                  cellClassName={classes.list_create}
                />
                <TextField
                  source="content"
                  label="resources.news.fields.content"
                  headerClassName={classes.list_content}
                  cellClassName={classes.list_content}
                />
              </Datagrid>
            </List>
          </div>
        )}
      </Route>
    </div>
  );
};

export default NewsList;
