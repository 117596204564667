import * as React from "react";
import { ListProps, useQueryWithStore, useTranslate } from "react-admin";
import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClassIcon from "@material-ui/icons/Class";
import DescriptionIcon from "@material-ui/icons/Description";
import FilterListIcon from "@material-ui/icons/FilterList";
import TimelineIcon from "@material-ui/icons/Timeline";

import { HotTable } from "@handsontable/react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import useFileDownload from "../hook/useFileDownload";
import useAuth from "../hook/useAuth";
import LegalEdit from "../legals/LegalEdit";
import { Route, RouteChildrenProps, useHistory } from "react-router-dom";
import AccordionFillterList from "../categorys/AccordionFillterList";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  hottable: {
    display: "inline-block",
    verticalAlign: "top",
    overflow: "hidden",
  },
  nb_commands: { color: "purple" },
  hiddenOnSmallScreens: {
    display: "table-cell",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  filterMenu: {
    verticalAlign: "top",
    marginTop: theme.spacing(4),
  },
  form: {
    marginLeft: theme.spacing(1),
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  formLabel: {
    paddingBottom: theme.spacing(1),
  },
  checkBox: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  drawerPaper: {
    zIndex: 200,
  },
  legend1: {
    color: "#000",
    backgroundColor: "#ddefe8",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    width: "6em",
  },
  legend2: {
    color: "#000",
    backgroundColor: "#6fc2b4",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    width: "6em",
  },
  legend3: {
    color: "#fff",
    backgroundColor: "#007680",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    width: "6em",
  },
  legend4: {
    backgroundColor: "#53565A",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(0.5),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(2),
    width: "6em",
  },
}));

const LegalsMap = (props: ListProps) => {
  const legalsmapData = useQueryWithStore({
    type: "getList",
    resource: "legalsmap",
    payload: {
      sort: { field: "id", order: "ASC" },
      pagination: { page: 1, perPage: 100 },
    },
  });
  const divisionList = useQueryWithStore({
    type: "getList",
    resource: "division",
    payload: {
      sort: { field: "id", order: "ASC" },
      pagination: { page: 1, perPage: 99999 },
    },
  });
  const requirementList = useQueryWithStore({
    type: "getList",
    resource: "requirement",
    payload: {
      sort: { field: "id", order: "ASC" },
      pagination: { page: 1, perPage: 99999 },
    },
  });
  const [myRow, setMyRow] = React.useState(
    Array.from(Array(requirementList?.data?.length)).map(() => false)
  );
  const [myCol, setMyCol] = React.useState(
    Array.from(Array(divisionList?.data?.length)).map(() => false)
  );
  const [myStatus, setMyStatus] = React.useState([false, false, false, false]);
  const [hotHeight, setHotHeight] = React.useState("83vh");
  const [hotWidth, setHotWidth] = React.useState("88%");
  const [formWidth, setFormWidth] = React.useState("12%");
  const [mapHeight, setMapHeight] = React.useState("100%");
  const [mapWidth, setMapWidth] = React.useState("86vw");
  const translate = useTranslate();
  const user = useAuth();
  const history = useHistory();
  const classes = useStyles();
  const hotTableComponent = React.useRef<HotTable>(null);
  const [fileDownload] = useFileDownload(user?.cognitoUser);
  const [openFilter, setOpenFilter] = React.useState<boolean>(true);
  const [openRequirementFilter, setOpenRequirementFilter] =
    React.useState<boolean>(true);
  const [openDivisionFilter, setOpenDivisionFilter] =
    React.useState<boolean>(true);
  const [openStatusFilter, setOpenStatusFilter] = React.useState<boolean>(true);

  window.addEventListener("resize", () => {
    setHotHeight("83vh");
    setHotWidth("88%");
    setFormWidth("12%");
    setMapHeight("100%");
    setMapWidth("86vw");
  });

  React.useEffect(() => {
    if (
      hotTableComponent?.current?.hotInstance &&
      requirementList?.data &&
      legalsmapData?.data &&
      history &&
      user?.cognitoUser &&
      fileDownload
    ) {
      hotTableComponent.current.hotInstance.addHook(
        "afterSelectionEnd",
        function () {
          if (arguments[1] !== 0) {
            const currentValue =
              hotTableComponent?.current?.hotInstance.getData(
                arguments[0],
                arguments[1],
                arguments[2],
                arguments[3]
              )[0][0];

            if (arguments[0] === 0) {
              requirementList?.data?.forEach((item) => {
                if (item.requirement_name === currentValue.name) {
                  fileDownload(item.storage_path);
                }
              });
            } else {
              legalsmapData?.data?.forEach((item) => {
                if (item.regulation_name_ja === currentValue.name) {
                  history.push(`/legalsmap/${item.regulation_id}`);
                }
              });
            }
          }
        }
      );
    }
  }, [
    hotTableComponent?.current?.hotInstance,
    requirementList?.data,
    legalsmapData?.data,
    history,
    fileDownload,
    user?.cognitoUser,
  ]);

  React.useEffect(() => {
    setMyRow(Array.from(Array(requirementList?.data?.length)).map(() => false));
    setMyCol(Array.from(Array(divisionList?.data?.length)).map(() => false));
  }, [
    divisionList.loading,
    requirementList.loading,
    divisionList?.data?.length,
    requirementList?.data?.length,
  ]);

  if (
    legalsmapData.loading ||
    divisionList.loading ||
    requirementList.loading
  ) {
    return <></>;
  }

  const rowHandleChange = (i) => {
    setMyRow(myRow?.map((r, index) => (index === i ? !r : r)));
  };

  const colHandleChange = (i) => {
    setMyCol(myCol?.map((c, index) => (index === i ? !c : c)));
  };

  const statusHandleChange = (i) => {
    setMyStatus(myStatus?.map((s, index) => (index === i ? !s : s)));
  };

  const firstRow = [
    {
      name: "",
      status: "",
      regulation_id: "",
      division_code: "",
      requirement_name: "",
    },
    requirementList?.data
      ?.map((data, i) => {
        return (myRow.includes(true) && myRow[i]) || !myRow.includes(true)
          ? {
              name: data.requirement_name,
              status: "",
              regulation_id: "",
              division_code: "",
              requirement_name: data.requirement_name,
            }
          : null;
      })
      .filter((v) => v),
  ].flat();

  // 主要要件の優先順位 連続→飛び石→単独→主要要件なし
  const requrementSortPriority = (record) => {
    if (record.filter((cell) => cell.regulation_id !== "").length === 0)
      return 3;

    const filteredRequirementList = requirementList?.data?.filter(
      (_, index) =>
        (myRow.includes(true) && myRow[index]) || !myRow.includes(true)
    );
    const require_index = filteredRequirementList.filter((req) =>
      record.some(
        (v) =>
          v.requirement_name === req.requirement_name && v.regulation_id !== ""
      )
    );
    if (
      require_index.length <= 1 ||
      Array.from(
        new Set(
          record
            .filter((cell) => cell.regulation_id !== "")
            .map((cell) => cell.regulation_id)
        )
      ).length > 1
    )
      return 2;

    return require_index.some((require, index, array) => {
      if (index < array.length - 1) {
        if (
          filteredRequirementList[
            filteredRequirementList.findIndex(
              (f) => f.requirement_name === require.requirement_name
            ) + 1
          ].requirement_name !== array[index + 1].requirement_name
        ) {
          return true;
        }
      }
      return false;
    })
      ? 1
      : 0;
  };

  const bodyRow = legalsmapData?.data
    ?.map((rData) => {
      const reqList = rData?.requirement_names?.split(",");
      const body = requirementList?.data
        ?.map((req, i) => {
          return (myRow.includes(true) && myRow[i]) || !myRow.includes(true)
            ? reqList?.includes(req.requirement_name)
              ? {
                  name: rData?.regulation_name_ja,
                  status: rData?.status_code,
                  regulation_id: rData?.regulation_id,
                  division_code: rData?.division_code,
                  requirement_name: req.requirement_name,
                }
              : {
                  name: "",
                  status: "",
                  regulation_id: "",
                  division_code: rData?.division_code,
                  requirement_name: req.requirement_name,
                }
            : null;
        })
        .filter((v) => v !== null);
      const colIndex = divisionList?.data
        ? divisionList?.data?.findIndex(
            ({ division_name }) => division_name === rData.division_name
          )
        : -1;
      return ((myCol.includes(true) && myCol[colIndex]) ||
        !myCol.includes(true)) &&
        ((myStatus.includes(true) && myStatus[rData?.status_code - 1]) ||
          !myStatus.includes(true))
        ? [
            {
              name: rData.division_name,
              status: "",
              regulation_id: "",
              division_code: rData?.division_code,
              requirement_name: "",
            },
            body,
          ].flat()
        : null;
    })
    .filter((v) => v)
    .sort((a, b) => {
      if (a[0].division_code > b[0].division_code) return 1;
      if (a[0].division_code < b[0].division_code) return -1;

      if (
        a.filter((cell) => cell.regulation_id !== "").length === 0 &&
        b.filter((cell) => cell.regulation_id !== "").length === 0
      )
        return 0;
      if (a.filter((cell) => cell.regulation_id !== "").length === 0) return 1;
      if (b.filter((cell) => cell.regulation_id !== "").length === 0) return -1;

      if (
        a.filter((cell) => cell.regulation_id !== "").length >
        b.filter((cell) => cell.regulation_id !== "").length
      )
        return -1;
      if (
        a.filter((cell) => cell.regulation_id !== "").length <
        b.filter((cell) => cell.regulation_id !== "").length
      )
        return 1;
      if (requrementSortPriority(a) > requrementSortPriority(b)) return 1;
      if (requrementSortPriority(a) < requrementSortPriority(b)) return -1;

      if (
        a.find((cell) => cell.regulation_id !== "") >
        b.find((cell) => cell.regulation_id !== "")
      )
        return 1;
      if (
        a.find((cell) => cell.regulation_id !== "") <
        b.find((cell) => cell.regulation_id !== "")
      )
        return -1;

      return 0;
    });

  let organizedBodyRow = bodyRow?.filter(
    (b) => b && b.some((cell, i) => i > 0 && cell?.name !== "")
  );

  // ESLint対策で関数をforの外に配置 主要要件にデータがあるか確認
  const haveRegulation = (req, j) =>
    organizedBodyRow[j].some(
      (cell) => cell.regulation_id !== "" && cell.requirement_name === req
    );

  // ESLint対策で関数をforの外に配置 上詰めしたレコードの発行
  const newRecord = (cell, index, i) => {
    return index === 0 || cell.regulation_id !== ""
      ? cell
      : organizedBodyRow[i][index];
  };

  // 上に詰める処理
  for (let i = 0; i < organizedBodyRow.length; i++) {
    const recordType = requrementSortPriority(organizedBodyRow[i]);
    const haveLegalRequrementList = organizedBodyRow[i]
      .filter((cell) => cell.regulation_id !== "")
      .map((cell) => cell.requirement_name);

    if (i > 0) {
      if (recordType === 0 || recordType === 2) {
        for (let j = i - 1; j >= 0; j--) {
          // 異なる規約区分、連続・飛び石、同じ主要要件を持つ場合にループを止めて一つ下の行にデータを移す
          if (
            organizedBodyRow[i][0].division_code !==
              organizedBodyRow[j][0].division_code ||
            requrementSortPriority(organizedBodyRow[j]) === 0 ||
            requrementSortPriority(organizedBodyRow[j]) === 1 ||
            haveLegalRequrementList.some((req) => haveRegulation(req, j))
          ) {
            if (i !== j + 1) {
              organizedBodyRow[j + 1] = organizedBodyRow[j + 1].map(
                (cell, index) => newRecord(cell, index, i)
              );
              organizedBodyRow[i] = organizedBodyRow[i].map((cell, index) => {
                return index === 0
                  ? cell
                  : {
                      name: "",
                      status: "",
                      regulation_id: "",
                      division_code: cell.division_code,
                      requirement_name: cell.requirement_name,
                    };
              });
            }

            break;
          }
        }
      }
    }
  }

  organizedBodyRow = organizedBodyRow?.filter(
    (b) => b && b.some((cell, i) => i > 0 && cell?.name !== "")
  );

  const data = [firstRow].concat(organizedBodyRow);

  const hotSettings = {
    data: data,
    renderer: (instance, td, row, col, prop, value, cellProperties) => {
      cellProperties.editor = false;
      if (row === 0) {
        td.textContent = value?.name;
        td.style.color = "white";
        td.style.background = "#000000";
        td.style.textAlign = "center";
        td.style.verticalAlign = "middle";
        td.style.cursor = "pointer";

        td.addEventListener(
          "mouseover",
          function (event) {
            event.target.style.color = "white";
            event.target.style.textDecoration = "underline";
          },
          false
        );
        td.addEventListener(
          "mouseout",
          function (event) {
            event.target.style.color = "white";
            event.target.style.textDecoration = "none";
          },
          false
        );
      } else if (col === 0) {
        td.textContent = value?.name?.replace("[row]", "");
        td.style.background = "#75787b";
        td.style.color = "white";
        td.style.textAlign = "center";
        td.style.verticalAlign = "middle";
      } else {
        if (!value || value?.name === "") {
          td.textContent = value?.name;
          td.style.color = "black";
          td.style.background = "#303030";
        } else {
          td.textContent =
            value?.name.length > 25
              ? value?.name.substring(0, 25) + "…"
              : value?.name;

          if (value?.status === 1) {
            td.style.background = "#ddefe8";
            td.style.color = "black";
          } else if (value?.status === 2) {
            td.style.background = "#6fc2b4";
            td.style.color = "black";
          } else if (value?.status === 3) {
            td.style.background = "#007680";
            td.style.color = "white";
          } else if (value?.status === 4) {
            td.style.background = "#53565A";
            td.style.color = "white";
          }

          td.style.textAlign = "center";
          td.style.verticalAlign = "middle";
          td.style.cursor = "pointer";
          td.addEventListener(
            "mouseover",
            function (event) {
              event.target.style.textDecoration = "underline";
            },
            false
          );
          td.addEventListener(
            "mouseout",
            function (event) {
              event.target.style.textDecoration = "none";
            },
            false
          );
        }
      }
      return td;
    },
    colHeaders: false,
    rowHeaders: false,
    strechH: "all",
    dropdownMenu: true,
    autoRowSize: false,
    autoColumnSize: false,
    rowHeights: 10,
    colWidths: data[0].map((_, i) => (i > 0 ? 200 : 80)),
    className: "custom-table",
    fixedRowsTop: 1,
    mergeCells: divisionList?.data
      ?.map((division) => {
        const divisionRow = data.map((d) => d[0]?.name);
        const rowIndex = divisionRow.indexOf(division.division_name);
        const rowspanLength =
          divisionRow.lastIndexOf(division.division_name) - rowIndex + 1;
        return rowIndex >= 0 && rowspanLength > 1
          ? {
              row: divisionRow.indexOf(division.division_name),
              col: 0,
              rowspan:
                divisionRow.lastIndexOf(division.division_name) -
                divisionRow.indexOf(division.division_name) +
                1,
              colspan: 1,
            }
          : undefined;
      })
      .filter((v) => v)
      .concat(
        data
          ?.map((record, i) => {
            return record
              .map((value, index) => {
                let count = 1;
                if (index > 0 && value?.name !== "") {
                  for (let j = index + 1; j < record.length; j++) {
                    if (
                      record[j]?.name !== "" &&
                      value?.name === record[j]?.name
                    ) {
                      count++;
                    } else {
                      break;
                    }
                  }
                }
                return index > 0 &&
                  count > 1 &&
                  (index === 1 || record[index - 1]?.name === "")
                  ? { row: i, col: index, rowspan: 1, colspan: count }
                  : undefined;
              })
              .filter((v) => v);
          })
          .flat()
      ),
  };

  const handleClose = () => {
    history.push("/legalsmap");
  };

  const handleRequirementToggle = () => {
    setOpenRequirementFilter((prevState) => (prevState ? false : true));
  };

  const handleDivisionToggle = () => {
    setOpenDivisionFilter((prevState) => (prevState ? false : true));
  };

  const handleStatusToggle = () => {
    setOpenStatusFilter((prevState) => (prevState ? false : true));
  };

  if (
    !requirementList?.data ||
    !divisionList?.data ||
    !legalsmapData?.data ||
    !history ||
    !fileDownload ||
    !user
  ) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Route path="/legalsmap/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );
          return (
            <React.Fragment>
              <IconButton
                size="small"
                onClick={() => setOpenFilter(!openFilter)}
                style={{ position: "absolute" }}
              >
                {!openFilter && <FilterListIcon />}
                {openFilter && <FilterListIcon />}
              </IconButton>
              <Box style={{ width: mapWidth }}>
                <Box
                  className={classes.filterMenu}
                  style={{
                    width: formWidth,
                    display: openFilter ? "inline-block" : "none",
                  }}
                >
                  <Box className={classes.form}>
                    {requirementList?.data && (
                      <AccordionFillterList
                        handleToggle={handleRequirementToggle}
                        name="resources.requirement.name"
                        icon={<ClassIcon />}
                        isOpen={openRequirementFilter}
                      >
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormGroup>
                            {myRow.map((r, index) => (
                              <FormControlLabel
                                key={"row" + index}
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={r}
                                    onChange={() => rowHandleChange(index)}
                                    name={
                                      requirementList?.data.length !== 0
                                        ? requirementList?.data[index]
                                            ?.requirement_name
                                        : "loading"
                                    }
                                    color="primary"
                                    className={classes.checkBox}
                                  />
                                }
                                label={
                                  <Typography variant="body2">
                                    {requirementList?.data.length !== 0
                                      ? requirementList?.data[index]
                                          ?.requirement_name
                                      : "loading"}
                                  </Typography>
                                }
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </AccordionFillterList>
                    )}
                    {divisionList?.data && (
                      <AccordionFillterList
                        handleToggle={handleDivisionToggle}
                        name="resources.division.name"
                        icon={<DescriptionIcon />}
                        isOpen={openDivisionFilter}
                      >
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormGroup>
                            {myCol.map((c, index) => (
                              <FormControlLabel
                                key={"col" + index}
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={c}
                                    onChange={() => colHandleChange(index)}
                                    name={
                                      divisionList?.data.length !== 0
                                        ? divisionList?.data[index]
                                            ?.division_name
                                        : "loading"
                                    }
                                    color="primary"
                                    className={classes.checkBox}
                                  />
                                }
                                label={
                                  <Typography variant="body2">
                                    {divisionList?.data.length !== 0
                                      ? divisionList?.data[index]?.division_name
                                      : "loading"}
                                  </Typography>
                                }
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </AccordionFillterList>
                    )}
                    <AccordionFillterList
                      handleToggle={handleStatusToggle}
                      name="resources.status.name"
                      icon={<TimelineIcon />}
                      isOpen={openStatusFilter}
                    >
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormGroup>
                          {myStatus.map((s, index) => (
                            <FormControlLabel
                              key={"status" + index}
                              control={
                                <Checkbox
                                  size="small"
                                  checked={s}
                                  onChange={() => statusHandleChange(index)}
                                  name={translate(
                                    "resources.status.data." + (index + 1)
                                  )}
                                  color="primary"
                                  className={classes.checkBox}
                                />
                              }
                              label={
                                <Typography variant="body2">
                                  {translate(
                                    "resources.status.data." + (index + 1)
                                  )}
                                </Typography>
                              }
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </AccordionFillterList>
                  </Box>
                </Box>
                {legalsmapData?.data && (
                  <Box
                    className={classes.hottable}
                    style={{
                      height: mapHeight,
                      width: openFilter ? hotWidth : "100%",
                    }}
                  >
                    <Grid container justifyContent="flex-end">
                      <Grid item className={classes.legend1}>
                        <Typography
                          variant="body2"
                          style={{ textAlign: "center" }}
                        >
                          {translate("resources.status.data.1")}
                        </Typography>
                      </Grid>
                      <Grid item className={classes.legend2}>
                        <Typography
                          variant="body2"
                          style={{ textAlign: "center" }}
                        >
                          {translate("resources.status.data.2")}
                        </Typography>
                      </Grid>
                      <Grid item className={classes.legend3}>
                        <Typography
                          variant="body2"
                          style={{ textAlign: "center" }}
                        >
                          {translate("resources.status.data.3")}
                        </Typography>
                      </Grid>
                      <Grid item className={classes.legend4}>
                        <Typography
                          variant="body2"
                          style={{ textAlign: "center" }}
                        >
                          {translate("resources.status.data.4")}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Box style={{ height: hotHeight }}>
                      <HotTable
                        ref={hotTableComponent}
                        settings={hotSettings}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
              {legalsmapData?.data && (
                <Drawer
                  variant="persistent"
                  open={isMatch}
                  anchor="right"
                  onClose={handleClose}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  {isMatch ? (
                    <LegalEdit
                      id={(match as any).params.id}
                      onCancel={handleClose}
                      resource={"legals"}
                      basePath={"/legals"}
                    />
                  ) : null}
                </Drawer>
              )}
            </React.Fragment>
          );
        }}
      </Route>
    </div>
  );
};

export default LegalsMap;
