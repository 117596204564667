import { CognitoUserInterface } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";

export type UserProp = {
  cognitoUser?: CognitoUserInterface;
  userInfo?: Object;
  setCognitoUser?: (cognitoUser: CognitoUserInterface) => void;
  setUserInfo?: (userInfo: Object) => void;
};

export const UserContext = createContext<UserProp>({
  cognitoUser: undefined,
  setCognitoUser: (undefined as unknown) as UserProp["setCognitoUser"],
  userInfo: undefined,
  setUserInfo: (undefined as unknown) as UserProp["setUserInfo"],
});

export const UserContextProvider: React.FC = ({ children }) => {
  const context: UserProp = useContext(UserContext);

  const [cognitoUser, setCognitoUser] = useState<
    CognitoUserInterface | undefined
  >(context.cognitoUser);

  const [userInfo, setUserInfo] = useState<Object | undefined>(
    context.userInfo
  );

  const newContext: UserProp = {
    cognitoUser,
    setCognitoUser,
    userInfo,
    setUserInfo,
  };

  const initialize = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const userInfo = await Auth.currentUserInfo();
      setCognitoUser(cognitoUser);
      setUserInfo(userInfo);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <UserContext.Provider value={newContext}>{children}</UserContext.Provider>
  );
};
