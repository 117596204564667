import axios from "axios";
import { generateUserRow } from "../util/JsonFormat";

export const getUserList = (user, refresh) => {
  const load = async (): Promise<void> => {
    const idToken = user?.cognitoUser?.signInUserSession?.idToken.jwtToken;
    const accessToken =
      user?.cognitoUser?.signInUserSession?.accessToken.jwtToken;

    const headers = {
      "Content-Type": "application/json",
      authorization: idToken,
      "x-access-token": accessToken,
    };

    const API_URL = process.env.REACT_APP_APIGATEWAY_ENDPOINT + "/prod/user";

    try {
      const response = await axios.post(API_URL + "/list", {}, { headers });
      if (response) {
        window.restServer.init({ users: generateUserRow(response.data) });
        refresh();
      } else {
        throw Error(response);
      }
    } catch (e) {
      console.error(e);
    }
  };
  load();
};
