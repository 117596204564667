export const mergeQueryResultJson = (
  regulation,
  project,
  division,
  category,
  requirement,
  content,
  user,
  history,
  projectRegulation,
  terms,
  news
) => {
  let result = {
    categorys: null,
    legals: null,
    news: null,
    projects: null,
    statuses: null,
    requirement: null,
    division: null,
    users: null,
    legalsmap: null,
    terms: [
      {
        version: "",
        jpTermFilePath: "",
        cnTermFilePath: "",
      },
    ],
  };
  result.categorys = category;
  result.legals = generateRegulationRow(
    regulation,
    content,
    requirement,
    history
  );
  result.projects = generateProjectRow(project, projectRegulation, regulation);
  result.news = generateNewsRow(news);
  result.statuses = category;
  result.requirement = requirement;
  result.division = division;
  result.users = generateUserRow(user);
  result.legalsmap = regulation;
  result.terms = [
    {
      version: terms.split(",")[0],
      jpTermFilePath: terms.split(",")[1],
      cnTermFilePath: terms.split(",")[2],
    },
  ];
  return result;
};

const generateProjectRow = (project, projectRegulation, regulation) =>
  project.map((item) => ({
    ...item,
    regulations: projectRegulation
      .filter((pr) => pr.project_id === item.project_id)
      .map((pr) => ({
        regulation_id: pr.regulation_id,
        regulation_name: regulation.find(
          (reg) => reg.regulation_id === pr.regulation_id
        )?.regulation_name_ja,
      })),
  }));

const generateRegulationRow = (regulation, content, requirement, history) =>
  regulation.map((item) => ({
    id: item.regulation_id,
    name: item.regulation_name_ja,
    cnName: item.regulation_name_native,
    category: item.category_name,
    description: item.description,
    division: item.division_name,
    status: item.status_code,
    releaseFlg: item.release_flg,
    projectName: item?.project_names?.split(","),
    content: content.filter((con) => con.regulation_id === item.regulation_id),
    requirement: item?.requirement_names?.split(","),
    requirementDetail: requirement.filter((req) => {
      const reqNames = item?.requirement_names?.split(",");
      return !reqNames ? false : reqNames.includes(req.requirement_name);
    }),
    entryDate: item.regulation_entry_date,
    announceDate: item.regulation_announce_date,
    create: item.create_date,
    lastupdate: item.update_date,
    publishCriteriaDate: item.publish_criteria_date,
    project: "",
    history: history.filter((his) => his.regulation_id === item.regulation_id),
  }));

export const generateUserRow = (users) =>
  users.map((item) => ({
    id: item.Attributes.find((v) => v.Name === "sub").Value,
    name: item.Attributes.find((v) => v.Name === "email").Value,
    create: item.UserCreateDate,
    lastupdate: item.UserLastModifiedDate,
  }));

export const generateNewsRow = (news) =>
  news.map((item) => ({
    id: item.news_id,
    content: item.news_content,
    create: item.create_date,
    lastupdate: item.update_date,
    path: item.news_path,
  }));
