import * as React from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FlagIcon from "@material-ui/icons/Flag";
import { Link } from "react-router-dom";
import { useTranslate, useQueryWithStore } from "react-admin";

import CardWithIcon from "./CardWithIcon";
import { Project } from "../types";

const ProjectList = () => {
  const translate = useTranslate();
  const classes = useStyles();

  const { loaded, data: projects } = useQueryWithStore({
    type: "getList",
    resource: "projects",
    payload: {
      sort: { field: "project_id", order: "ASC" },
      pagination: { page: 1, perPage: 100 },
    },
  });

  if (!loaded) return null;

  const nb = projects ? projects.reduce((nb: number) => ++nb, 0) : 0;
  return (
    <CardWithIcon
      to="/projects"
      icon={FlagIcon}
      title={translate("pos.dashboard.favorites")}
      subtitle={nb}
    >
      <List className={classes.list}>
        {projects
          ? projects.map((record: Project, index) =>
              index < 7 ? (
                <ListItem
                  button
                  to={`/projects/${record.id}`}
                  component={Link}
                  key={record.id}
                >
                  <ListItemText primary={`${record.project_name}`} />
                  <ListItemSecondaryAction>{`${record.regulations.length}`}</ListItemSecondaryAction>
                </ListItem>
              ) : null
            )
          : null}
      </List>
      <Box flexGrow="1">&nbsp;</Box>
      <Button
        className={classes.link}
        component={Link}
        to="/projects"
        size="small"
        color="primary"
      >
        <Box p={1} className={classes.linkContent}>
          {translate("pos.dashboard.all_favorites")}
        </Box>
      </Button>
    </CardWithIcon>
  );
};

const useStyles = makeStyles((theme) => ({
  link: {
    borderRadius: 0,
  },
  linkContent: {
    color: theme.palette.primary.main,
  },
  list: {
    height: "300px",
  },
}));

export default ProjectList;
