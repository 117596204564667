import { Card as MuiCard, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { FilterLiveSearch } from "react-admin";
import ProjectFilterListItem from "../categorys/ProjectFilterListItem";
import CategoryFilterListItem from "../categorys/CategoryFilterListItem";
import RequirementFilterListItem from "../categorys/RequirementFilterListItem";
import StatusFilterListItem from "../categorys/StatusFilterListItem";

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1,
      width: "23em",
      minWidth: "10em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

const Aside = () => (
  <Card>
    <CardContent>
      <FilterLiveSearch />
      <ProjectFilterListItem />
      <CategoryFilterListItem />
      <RequirementFilterListItem />
      <StatusFilterListItem />
    </CardContent>
  </Card>
);

export default Aside;
