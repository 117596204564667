import { Box, Link, Typography } from "@material-ui/core";
import { FieldProps } from "react-admin";
import { Legal } from "../types";
import { makeStyles } from "@material-ui/core/styles";
import useAuth from "../hook/useAuth";
import useFileDownload from "../hook/useFileDownload";

const useStyles = makeStyles({
  link: {
    cursor: "pointer",
  },
});

const RequirementList = ({ record }: FieldProps<Legal>) => {
  const classes = useStyles();
  const user = useAuth();
  const [fileDownload] = useFileDownload(user.cognitoUser);

  const handleClick = async (path) => {
    fileDownload(path);
  };

  return record ? (
    <Box>
      {record.requirementDetail &&
        record.requirementDetail.map((requirement, i) => {
          return requirement ? (
            <Box key={i}>
              <Typography
                component="span"
                variant="body1"
                className={classes.link}
              >
                <Link
                  color="primary"
                  onClick={() => handleClick(requirement.storage_path)}
                >
                  {requirement.requirement_name}
                </Link>
              </Typography>
            </Box>
          ) : null;
        })}
    </Box>
  ) : null;
};

RequirementList.defaultProps = {
  addLabel: true,
  label: "resources.legals.fields.requirement",
};

export default RequirementList;
