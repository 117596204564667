import { Box, Typography } from "@material-ui/core";
import { Fragment, useCallback, ReactElement } from "react";
import {
  CreateButton,
  Datagrid,
  List,
  ListProps,
  TextField,
  TopToolbar,
  useTranslate,
} from "react-admin";
import { Route, RouteChildrenProps, useHistory } from "react-router-dom";
import { Drawer, useMediaQuery, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ProjectEdit from "./ProjectEdit";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  list_root: {
    width: "75%",
  },
  data_grid: {
    backgroundColor: "#212121",
  },
  nb_commands: { color: "purple" },
  hiddenOnSmallScreens: {
    display: "table-cell",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  drawerPaper: {
    zIndex: 100,
  },
  list_id: {
    width: "10%",
  },
  list_name: {
    width: "30%",
  },
  list_desc: {
    width: "60%",
  },
  empty: {
    marginTop: "1em",
  },
}));

const Empty = () => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box textAlign="center" m={2}>
      <Typography variant="h4" paragraph>
        {translate("resources.projects.empty.empty")}
      </Typography>
      <Typography variant="body1">
        {translate("resources.projects.empty.invite")}
      </Typography>
      <CreateButton
        className={classes.empty}
        variant="contained"
        label="resources.projects.create.create"
      />
    </Box>
  );
};

const ProjectActions = (props) => (
  <TopToolbar>
    <CreateButton
      variant="contained"
      label="resources.projects.create.create"
    />
  </TopToolbar>
);

const ProjectList = (props: ListProps): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const handleClose = useCallback(() => {
    history.push("/projects");
  }, [history]);

  return (
    <div className={classes.root}>
      <Route path="/projects/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== "create"
          );
          return (
            <Fragment>
              <List
                {...props}
                filters={isSmall ? undefined : undefined}
                sort={{ field: "project_name", order: "ASC" }}
                perPage={25}
                exporter={false}
                bulkActionButtons={false}
                actions={<ProjectActions />}
                classes={{ root: classes.list_root }}
                empty={<Empty />}
              >
                <Datagrid
                  optimized
                  rowClick="edit"
                  classes={{ headerCell: classes.data_grid }}
                >
                  <TextField
                    source="project_name"
                    label="resources.projects.fields.name"
                    cellClassName={classes.list_name}
                    headerClassName={classes.list_name}
                  />
                  <TextField
                    source="description"
                    label="resources.projects.fields.description"
                    cellClassName={classes.list_desc}
                    headerClassName={classes.list_desc}
                  />
                </Datagrid>
              </List>
              <Drawer
                variant="persistent"
                open={true}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {isMatch ? (
                  <ProjectEdit
                    id={(match as any).params.id}
                    onCancel={handleClose}
                    resource={props.resource}
                    basePath={props.basePath}
                  />
                ) : null}
              </Drawer>
            </Fragment>
          );
        }}
      </Route>
    </div>
  );
};

export default ProjectList;
