import { FieldProps, TextField } from "react-admin";
import { Legal } from "../types";

const NameField = ({ record }: FieldProps<Legal>) => {
  return (
    <>
      <TextField source="id" style={{ fontSize: "10px" }} />
      <br />
      <TextField source="name" label="resources.legals.fields.name" />
    </>
  );
};

NameField.defaultProps = {
  addLabel: true,
};

export default NameField;
