import { useEffect, CSSProperties } from "react";
import { useTranslate, useVersion } from "react-admin";
import {
  Box,
  Card,
  useMediaQuery,
  Theme,
  Typography,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import WeeklyUpdateLegalList from "./WeeklyUpdateLegalList";
import ProjectList from "./ProjectList";
import publishArticleImage from "./logo.png";
import WeeklyUpdateLegalCard from "./WeeklyUpdateLegalCard";
import NewsList from "./NewsList";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      theme.palette.type === "dark"
        ? "#303030"
        : `linear-gradient(to right, #8975fb 0%, #746be7 35%), linear-gradient(to bottom, #8975fb 0%, #6f4ceb 50%), #6f4ceb`,

    color: "#fff",
    padding: 20,
    marginTop: theme.spacing(1),
    marginBottom: "0em",
  },
  news: {
    marginTop: theme.spacing(1),
  },
  media: {
    background: `url(${publishArticleImage}) top right / cover`,
    marginLeft: "auto",
  },
  actions: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
      flexWrap: "wrap",
      "& a": {
        marginLeft: "0!important",
        marginRight: "1em",
      },
    },
  },
}));

const styles = {
  flex: { display: "flex", marginTop: "0.5em" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1.5, marginRight: "0.5em" },
  rightCol: { flex: 0.5, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Dashboard = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const version = useVersion();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  useEffect(() => {}, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  return isXSmall ? (
    <div style={styles.flexColumn as CSSProperties}>
      <Card className={classes.root}>
        <Box display="flex">
          <Box flex="1">
            <Typography variant="h5" component="h2" gutterBottom>
              {translate("pos.dashboard.welcome.title")}
            </Typography>
            <Box>
              <Typography variant="body1" component="p" gutterBottom>
                {translate("pos.dashboard.welcome.subtitle1")}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" component="p" gutterBottom>
                {translate("pos.dashboard.welcome.subtitle2")}
              </Typography>
            </Box>
          </Box>
          <Box
            display={{ xs: "none", sm: "none", md: "block" }}
            className={classes.media}
            width="120px"
            height="120px"
            overflow="hidden"
          />
        </Box>
        <Divider style={{ marginTop: "10px" }} />
        <Box display="flex">
          <NewsList />
        </Box>
      </Card>
      <div style={styles.flex}>
        <WeeklyUpdateLegalCard title="pos.dashboard.weekly_update_legal" />
      </div>
      <div style={styles.flex}>
        <WeeklyUpdateLegalList />
      </div>
      <div style={styles.flex}>
        <ProjectList />
      </div>
    </div>
  ) : isSmall ? (
    <>
      <Card className={classes.root}>
        <Box display="flex">
          <Box flex="1">
            <Typography variant="h5" component="h2" gutterBottom>
              {translate("pos.dashboard.welcome.title")}
            </Typography>
            <Box>
              <Typography variant="body1" component="p" gutterBottom>
                {translate("pos.dashboard.welcome.subtitle1")}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" component="p" gutterBottom>
                {translate("pos.dashboard.welcome.subtitle2")}
              </Typography>
            </Box>
          </Box>
          <Box
            display={{ xs: "none", sm: "none", md: "block" }}
            className={classes.media}
            width="120px"
            height="120px"
            overflow="hidden"
          />
        </Box>
        <Divider style={{ marginTop: "10px" }} />
        <Box display="flex">
          <NewsList />
        </Box>
      </Card>
      <div style={styles.flex}>
        <WeeklyUpdateLegalCard title="pos.dashboard.weekly_update_legal" />
      </div>
      <div style={styles.flex}>
        <WeeklyUpdateLegalList />
      </div>
      <div style={styles.flex}>
        <ProjectList />
      </div>
    </>
  ) : (
    <>
      <Card className={classes.root}>
        <Box display="flex">
          <Box flex="1">
            <Typography variant="h5" component="h2" gutterBottom>
              {translate("pos.dashboard.welcome.title")}
            </Typography>
            <Box>
              <Typography variant="body1" component="p" gutterBottom>
                {translate("pos.dashboard.welcome.subtitle1")}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" component="p" gutterBottom>
                {translate("pos.dashboard.welcome.subtitle2")}
              </Typography>
            </Box>
          </Box>
          <Box
            display={{ xs: "none", sm: "none", md: "block" }}
            className={classes.media}
            width="120px"
            height="120px"
            overflow="hidden"
          />
        </Box>
        <Divider style={{ marginTop: "10px" }} />
        <Box display="flex">
          <NewsList />
        </Box>
      </Card>
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <WeeklyUpdateLegalCard title="pos.dashboard.weekly_update_legal" />
          </div>
          <div style={styles.singleCol}>
            <WeeklyUpdateLegalList />
          </div>
        </div>
        <div style={styles.rightCol}>
          <div style={styles.flex}>
            <ProjectList />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
