import { useState } from "react";
import axios from "axios";

type OperationId = "create" | "delete";

export const useUserEditQuery = function () {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<Array<any>>();
  const [error, setError] = useState<Error>();

  const API_URL = process.env.REACT_APP_APIGATEWAY_ENDPOINT + "/prod/user";

  const update = async (
    operationIdMome: OperationId,
    pramQueryVariable: object,
    idToken: string,
    accessToken: string
  ): Promise<void> => {
    setIsLoading(true);

    const headers = {
      "Content-Type": "application/json",
      authorization: idToken,
      "x-access-token": accessToken,
    };

    try {
      const response = await axios.post(
        API_URL + "/" + operationIdMome,
        pramQueryVariable,
        { headers }
      );
      if (response) {
        setResult(response.data);
      } else {
        throw Error(response);
      }
    } catch (e) {
      console.error(e);
      setError(e as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return [update, { isLoading, error, result }] as const;
};

export default useUserEditQuery;
