import PeopleIcon from "@material-ui/icons/People";
import UserCreate from "./UserCreate";

import UserList from "./UserList";

const exportedObject = {
  list: UserList,
  create: UserCreate,
  icon: PeopleIcon,
};

export default exportedObject;
