import { FilterListItem, useQueryWithStore } from "react-admin";
import ClassIcon from "@material-ui/icons/Class";
import { Requirement } from "../types";
import AccordionFillterList from "./AccordionFillterList";
import { useState } from "react";

const RequirementFilterListItem = () => {
  const [open, setOpen] = useState<boolean>(true);

  const handleToggle = () => {
    setOpen((prevState) => (prevState ? false : true));
  };

  const { loaded, data: requirements } = useQueryWithStore({
    type: "getList",
    resource: "requirement",
    payload: {
      sort: { field: "requirement_code", order: "ASC" },
      pagination: { page: 1, perPage: 100 },
    },
  });

  if (!loaded) return null;

  return (
    <AccordionFillterList
      handleToggle={handleToggle}
      name="resources.legals.filters.req"
      icon={<ClassIcon />}
      isOpen={open}
    >
      {requirements
        ? requirements.map((record: Requirement) => (
            <FilterListItem
              key={record.requirement_code}
              label={record.requirement_name}
              value={{ requirement: record.requirement_name }}
            />
          ))
        : null}
    </AccordionFillterList>
  );
};

export default RequirementFilterListItem;
